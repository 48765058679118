import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Chip, Divider, Paper, Skeleton, Snackbar, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import WidgetWrapper from "components/WidgetWrapper";
import { Formik } from "formik";
import { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ViewEnum } from 'utils/views.constants';
import AppHeader from "widgets/AppHeader/AppHeader";
import DepositAdminWindow from 'widgets/DepositAdminWindow/DepositAdminWindow';
import Navigation from "widgets/Navigation/Navigation";
import UserBalanceAdminWindow from "widgets/UserBalanceAdminWindow/UserBalanceAdminWindow";
import WithdrawAdminWindow from 'widgets/WithdrawAdminWindow/WithdrawAdminWindow';


const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const columns = [
  {
    field: "username",
    headerName: "Username",
    disableColumnMenu: true,
    width: 200
  },
  {
    field: "level",
    headerName: "Lvl",
    disableColumnMenu: true,
    width: 70
  },
  {
    field: "balance",
    headerName: "Balance",
    disableColumnMenu: true
  },
  {
    field: "depositAmount",
    headerName: "Deposit",
    disableColumnMenu: true
  },
  {
    field: "withdrawAmount",
    headerName: "Withdraw",
    disableColumnMenu: true
  }
];

const AdminReportPage = () => {

  const systemWalletColumns = [
    {
      field: "walletId",
      headerName: "Wallet Id",
      width: 400,
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: ({ row }) =>
        <Button onClick={() => deleteWallet(row.id)}>
          Delete
        </Button>,
    },
  ];

  const depositRequestsColumns = [
    {
      field: "id",
      headerName: "Reference",
      width: 220,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      disableColumnMenu: true,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 220,
      disableColumnMenu: true,
    },
    
    // {
    //   field: "action",
    //   headerName: "Action",
    //   sortable: false,
    //   renderCell: ({ row }) =>
    //     <Button onClick={() => deleteWallet(row.id)}>
    //       Delete
    //     </Button>,
    // },
  ];

  const withdrawRequestsColumns = [
    {
      field: "id",
      headerName: "Reference",
      width: 220,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      disableColumnMenu: true,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 220,
      disableColumnMenu: true,
    },
    
    // {
    //   field: "action",
    //   headerName: "Action",
    //   sortable: false,
    //   renderCell: ({ row }) =>
    //     <Button onClick={() => deleteWallet(row.id)}>
    //       Delete
    //     </Button>,
    // },
  ];

  const theme = useTheme();
  const isNonMobileScreen = useMediaQuery("(min-width: 600px)");

  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  
  const axiosHeaders = {headers: {Authorization: "Bearer " + token}}

  const [teamReport, setTeamReport] = useState({members: []});
  const [systemWallets, setSystemWallets] = useState([]);
  const [depositRequests, setDepositRequests] = useState([]);
  const [withdrawRequests, setWithdrawRequests] = useState([]);
  const [teamReportLoading, setTeamReportLoading] = useState(false);

  const [view, setView] = useState(ViewEnum.TEAM_REPORT)
  const [userToModify, setuserToModify] = useState(null);
  const [requestToHandle, setRequestToHandle] = useState(null);

  const [refresh, setRefresh] = useState(false);
  const [refreshWalletsTable, setRefreshWalletsTable] = useState(false);
  const [refreshDepositRequestsTable, setRefreshDepositRequestsTable] = useState(false);
  const [refreshWithdrawRequestsTable, setRefreshWithdrawRequestsTable] = useState(false);

  const [loading, setLoading] = useState(false);
  
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    position: {
      vertical: 'top',
      horizontal: 'right',
    },
    snackMessage: '',
    severity: '',
  });
  
  // ADD SystemWallet Flow
  const initialSystemWallet = {
    walletId: "",
  };

  const handleWalletSubmit = async (values, onSubmitProps) => {
    await addSystemWallet(values, onSubmitProps);
  };

  const addSystemWallet = async(values, onSubmitProps) => {
    setLoading(true);
    const body = {
      walletId: values.walletId
    }
    axios.post(apiBaseUrl + "/wallet", body, axiosHeaders)
    .then(res => {
      onSubmitProps.resetForm();
      handleSnackbarOpen("Wallet added succesfully!", 'success');
      setLoading(false);
      setRefreshWalletsTable(true);
    })
    .catch(function (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.status === 400) {
          onSubmitProps.setFieldError(error.response.data.field, error.response.data.error)
          handleSnackbarOpen(error.response.data.error, 'error');
        }
      } else if (error.request) {
        console.log(error);
        handleSnackbarOpen("Error request: " + error.request.responseText, 'error');

      } else {
        handleSnackbarOpen('Error ' + error.message, 'error');
      }
    });
  }

  const deleteWallet = (id) => {
    axios.delete(apiBaseUrl + "/wallet/" + id, axiosHeaders)
    .then(res => {
      console.log(res);
      setRefreshWalletsTable(true);
      handleSnackbarOpen("Wallet deleted succesfully!", 'success');
    })
    .catch(function (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.status === 400) {
          handleSnackbarOpen(error.response.data.error, 'error');
        }
      } else if (error.request) {
        console.log(error);
        handleSnackbarOpen("Error request: " + error.request.responseText, 'error');

      } else {
        handleSnackbarOpen('Error ' + error.message, 'error');
      }
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleSnackbarOpen = (newSnackMessage, severity) => {
    setSnackbarState({ ...snackbarState, open: true, snackMessage: newSnackMessage, severity: severity });
  };


  const onBackClick = () => {
    setView(ViewEnum.TEAM_REPORT);
    setRefresh(true);
    setRefreshWalletsTable(true);
    setRefreshDepositRequestsTable(true);
    setRefreshWithdrawRequestsTable(true);
  }

  useEffect(() => {
    setTeamReportLoading(true)
    axios.get(apiBaseUrl + "/users/" + user._id + "/team-report/admin", axiosHeaders)
    .then(res => {
      setTeamReport(res.data)
      setTeamReportLoading(false)
    })
    .catch(function(err) {
      if (err.response) {
        if (err.response.status === 400) {
          console.log(err)
        }
      } else if (err.request) {
        console.log(err)
      } else {
        console.log(err)
      }
    });

    setRefresh(false);
  }, [refresh])

  useEffect(() => {
    axios.get(apiBaseUrl + "/wallet", axiosHeaders)
    .then(res => {
      setSystemWallets(res.data)
    })
    .catch(function(err) {
      if (err.response) {
        if (err.response.status === 400) {
          console.log(err)
        }
      } else if (err.request) {
        console.log(err)
      } else {
        console.log(err)
      }
    });

    setRefreshWalletsTable(false);
  }, [refreshWalletsTable])

  useEffect(() => {
    axios.get(apiBaseUrl + "/requests/deposits/admin", axiosHeaders)
    .then(res => {
      setDepositRequests(res.data.requests)
    })
    .catch(function(err) {
      if (err.response) {
        if (err.response.status === 400) {
          console.log(err)
        }
      } else if (err.request) {
        console.log(err)
      } else {
        console.log(err)
      }
    });

    setRefreshDepositRequestsTable(false);
  }, [refreshDepositRequestsTable])

  useEffect(() => {
    axios.get(apiBaseUrl + "/requests/withdraws/admin", axiosHeaders)
    .then(res => {
      setWithdrawRequests(res.data.requests)
    })
    .catch(function(err) {
      if (err.response) {
        if (err.response.status === 400) {
          console.log(err)
        }
      } else if (err.request) {
        console.log(err)
      } else {
        console.log(err)
      }
    });

    setRefreshWithdrawRequestsTable(false);
  }, [refreshWithdrawRequestsTable])



  const openUserBalanceAdminWindow = (params) => {
    setuserToModify(params.row)
    setView(ViewEnum.USER_BALANCE_ADMIN)
  }

  const openDepositRequestAdminWindow = (params) => {
    setRequestToHandle(params.row)
    setView(ViewEnum.DEPOSIT_ADMIN)
  }

  const openWithdrawRequestAdminWindow = (params) => {
    setRequestToHandle(params.row)
    setView(ViewEnum.WITHDRAW_ADMIN)
  }

  return(
    <>
      <AppHeader/>
      <Snackbar
        anchorOrigin={snackbarState.position}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        autoHideDuration={6000}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarState.severity} sx={{ width: '100%' }}>
          {snackbarState.snackMessage}
        </Alert>
      </Snackbar>
      { view === ViewEnum.TEAM_REPORT && (
      <Box backgroundColor={theme.palette.background.paper} width="100%" height="100%"
      sx = {{
        overflow: "hidden",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "8em"
      }}
      >
        {teamReportLoading ? (
            <Skeleton variant="rounded" height= "100%"/>
          ) : (
          <>
          <WidgetWrapper sx={{width: isNonMobileScreen ? "85%" : "95%", marginLeft: "auto", marginRight: "auto", marginBottom: "1vh"}}>
            <Card
                sx={{
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                  display: "grid"
                }}
              >
                <Typography sx={{fontWeight: "bold", fontSize: "1.2rem", gridColumn: "span 2", justifyContent: "center", display: "flex"}}>Team Report</Typography>
                <Paper elevation={3} sx={{
                  gridColumn: "span 1",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  display: 'flex',
                  justifyContent: 'center',   
                  alignItems: 'center',       
                  flexDirection: 'column',
                  margin: "0.5rem"
                }}>
                  <Typography sx={{fontSize: "0.8rem"}}>Team Balance:</Typography>
                  <Typography sx={{fontSize: "1.5rem", color: theme.palette.primary.main}}>{parseFloat(teamReport.teamBalance).toFixed(3)}</Typography>
                </Paper>
                <Paper elevation={3} sx={{
                  gridColumn: "span 1",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  display: 'flex',
                  justifyContent: 'center',   
                  alignItems: 'center',       
                  flexDirection: 'column',
                  margin: "0.5rem"
                }}>
                  <Typography sx={{fontSize: "0.8rem"}}>Team size:</Typography>
                  <Typography sx={{fontSize: "1.5rem", color: theme.palette.primary.main}}>{teamReport.teamSize}</Typography>
                </Paper>
                <Paper elevation={3} sx={{
                  gridColumn: "span 1",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  display: 'flex',
                  justifyContent: 'center',   
                  alignItems: 'center',       
                  flexDirection: 'column',
                  margin: "0.5rem"
                }}>
                  <Typography sx={{fontSize: "0.8rem"}}>Team Deposit:</Typography>
                  <Typography sx={{fontSize: "1.5rem", color: theme.palette.primary.main}}>{parseFloat(teamReport.teamDeposit).toFixed(3)}</Typography>
                </Paper>
                <Paper elevation={3} sx={{
                  gridColumn: "span 1",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  display: 'flex',
                  justifyContent: 'center',   
                  alignItems: 'center',       
                  flexDirection: 'column',
                  margin: "0.5rem"
                }}>
                  <Typography sx={{fontSize: "0.8rem"}}>Team withdraw:</Typography>
                  <Typography sx={{fontSize: "1.5rem", color: theme.palette.primary.main}}>{parseFloat(teamReport.teamWithdraw).toFixed(3)}</Typography>
                </Paper>
                <Paper elevation={3} sx={{
                  gridColumn: "span 1",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  display: 'flex',
                  justifyContent: 'center',   
                  alignItems: 'center',       
                  flexDirection: 'column',
                  margin: "0.5rem"
                }}>
                  <Typography sx={{fontSize: "0.8rem"}}>New members today:</Typography>
                  <Typography sx={{fontSize: "1.5rem", color: theme.palette.primary.main}}>{teamReport.newMembersToday}</Typography>
                </Paper>
                <Paper elevation={3} sx={{
                  gridColumn: "span 1",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  display: 'flex',
                  justifyContent: 'center',   
                  alignItems: 'center',       
                  flexDirection: 'column',
                  margin: "0.5rem"
                }}>
                  <Typography sx={{fontSize: "0.8rem"}}>Active members today:</Typography>
                  <Typography sx={{fontSize: "1.5rem", color: theme.palette.primary.main}}>{teamReport.activeMembersToday}</Typography>
                </Paper>
            </Card>
          </WidgetWrapper>
          
          <Divider sx={{margin: "2.6em 0.5rem 0.5rem"}}>
            <Chip label="Users" />
          </Divider>

          <WidgetWrapper>
          <DataGrid
            rows={teamReport.members}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onRowClick = {(params, event, details) => {openUserBalanceAdminWindow(params)}}
          />
          </WidgetWrapper>

          <Divider sx={{margin: "2.6em 0.5rem 0.5rem"}}>
            <Chip label="System Wallets" />
          </Divider>

          <Formik
            onSubmit={ handleWalletSubmit }
            initialValues={ initialSystemWallet }
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap= {isNonMobileScreen ? "30px" : "10px"}
                  gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                  sx={{
                    marginLeft: "1rem",
                    marginRight: "1rem",
                  }}
                >
                  <TextField
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.walletId}
                    name="walletId"
                    error={Boolean(touched.walletId) && Boolean(errors.walletId)}
                    helperText={touched.walletId && errors.walletId}
                    size={isNonMobileScreen ? "normal" : "small" }
                    sx={{ gridColumn: "span 1" }}
                  />

                  <LoadingButton
                    fullWidth
                    type="submit"
                    loading={loading}
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.background.paper,
                      "&:hover": { 
                        backgroundColor: theme.palette.secondary.main,
                      },
                    }}
                  >
                    { "Submit" }
                  </LoadingButton>
                </Box>
              </form>
            )}
          </Formik>

          <WidgetWrapper>
          <DataGrid
            rows={systemWallets}
            columns={systemWalletColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            disableRowSelectionOnClick
          />
          </WidgetWrapper>

          <Divider sx={{margin: "2.6em 0.5rem 0.5rem"}}>
            <Chip label="Deposit Requests" />
          </Divider>


          <WidgetWrapper>
          <DataGrid
            rows={depositRequests}
            columns={depositRequestsColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onRowClick = {(params, event, details) => {openDepositRequestAdminWindow(params)}}
          />
          </WidgetWrapper>

          <Divider sx={{margin: "2.6em 0.5rem 0.5rem"}}>
            <Chip label="Withdraw Requests" />
          </Divider>

          <WidgetWrapper>
          <DataGrid
            rows={withdrawRequests}
            columns={withdrawRequestsColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onRowClick = {(params, event, details) => {openWithdrawRequestAdminWindow(params)}}
          />
          </WidgetWrapper>
        </>)}
      </Box>
      )}
      { view === ViewEnum.USER_BALANCE_ADMIN && (
        <UserBalanceAdminWindow onBackClick={onBackClick} userToModify={userToModify}></UserBalanceAdminWindow>
      )}
      { view === ViewEnum.DEPOSIT_ADMIN && (
        <DepositAdminWindow onBackClick={onBackClick} requestToHandle={requestToHandle}></DepositAdminWindow>
      )}
      { view === ViewEnum.WITHDRAW_ADMIN && (
        <WithdrawAdminWindow onBackClick={onBackClick} requestToHandle={requestToHandle}></WithdrawAdminWindow>
      )}
      <Navigation/>
    </>
  );
}

export default AdminReportPage;