import { Card, Typography, useMediaQuery, useTheme } from '@mui/material';

const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const GenericRequestComponent = ({requestObject}) => {
  const theme = useTheme();

  const isNonMobileScreens = useMediaQuery("(min-width: 600px)");

  const getColorForStatus = (status) => {
    switch (status) {
      case 'PENDING':
        return theme.palette.primary.main;
      case 'COMPLETED':
        return theme.palette.success.main;
      case 'FAILED':
        return theme.palette.error.main;
    }
  };

  return(
    <Card 
      sx={{
        gridColumn: "span 4", 
        borderRadius: "1rem",
        padding: "1rem",
        position: "relative",
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))"
      }}
    >
      <Typography sx={{gridColumn: "span 1", fontSize: isNonMobileScreens ? "0.9rem" : "0.7rem"}}>Created at:</Typography>
      <Typography sx={{gridColumn: "span 1", fontSize: isNonMobileScreens ? "1rem" : "0.7rem", color: theme.palette.primary.main, marginLeft: "-1.5rem"}}>{requestObject.createdAt}</Typography>

      <Typography sx={{gridColumn: "span 1", fontSize: isNonMobileScreens ? "0.9rem" : "0.7rem"}}>Reference:</Typography>
      <Typography sx={{gridColumn: "span 1", fontSize: isNonMobileScreens ? "1rem" : "0.7rem", color: theme.palette.primary.main, marginLeft: "-1.5rem"}}>{requestObject._id}</Typography>

      {requestObject.type !== "DEPOSIT" && (
        <>
          <Typography sx={{gridColumn: "span 1", fontSize: isNonMobileScreens ? "0.9rem" : "0.7rem"}}>Withdraw Type:</Typography>
          <Typography sx={{gridColumn: "span 1", color: theme.palette.primary.main, fontSize: isNonMobileScreens ? "1rem" : "0.8rem"}}>{requestObject.type === "WITHDRAW_CRYPTO" ? "CRYPTO" : "CARD"}</Typography>
        </>
      )}

      <Typography sx={{margin: "auto", gridColumn: "span 2", fontSize: "1.2rem", color: getColorForStatus(requestObject.status)}}>{requestObject.status}</Typography>

    </Card>
  );
}

export default GenericRequestComponent;