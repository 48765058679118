import { Box, useMediaQuery, useTheme } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import { useState } from "react";
import { ViewEnum } from "utils/views.constants";
import AboutUsWindow from "widgets/AboutUsWindow/AboutUsWindow";
import AccountPageMenu from "widgets/AccountPageMenu/AccountPageMenu";
import AppHeader from "widgets/AppHeader/AppHeader";
import DepositWindow from "widgets/DepositWindow/DepositWindow";
import DepositWithdraw from "widgets/DepositWithdraw/DepositWithdraw";
import FinancesWindow from "widgets/FinancesWindow/FinancesWindow";
import InviteWindow from "widgets/InviteWindow/InviteWindow";
import Navigation from "widgets/Navigation/Navigation";
import ProfileWindow from "widgets/ProfileWindow/ProfileWindow";
import TutorialWindow from "widgets/TutorialWindow/TutorialWindow";
import UserWidget from "widgets/UserWidget/UserWidget";
import WithdrawWindow from "widgets/WithdrawWindow/WithdrawWindow";

const AccountPage = ({tawkMessengerRef}) => {

  const theme = useTheme();
  const isNonMobileScreen = useMediaQuery("(min-width: 600px)");
  const [view, setView] = useState(ViewEnum.ACCOUNT)

  const onBackClick = () => {
    setView(ViewEnum.ACCOUNT);
  }

  return(
    <>
      <AppHeader/>
      { view === ViewEnum.ACCOUNT && (
        <Box backgroundColor={theme.palette.background.paper} width="100%" height="100%"
        sx = {{
          overflow: "hidden",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          paddingBottom: "8em"
        }}
        >
          <UserWidget 
            sx = {{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justigyContent: "center",
              width: "100%",
            }}
          />

          <WidgetWrapper sx={{width: isNonMobileScreen ? "70%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "0.75rem"}}>
            <DepositWithdraw setView={setView}/>
          </WidgetWrapper>

          <WidgetWrapper sx={{width: isNonMobileScreen ? "50%" : "95%", marginLeft: "auto", marginRight: "auto", marginBottom: "9rem"}}>
            <AccountPageMenu setView={setView} tawkMessengerRef={tawkMessengerRef}/>
          </WidgetWrapper>
        </Box>
      )}
      {view  === ViewEnum.INVITE && (
        <InviteWindow onBackClick={onBackClick}></InviteWindow>
      )}
      {view === ViewEnum.WITHDRAW && (
        <WithdrawWindow setView={setView} onBackClick={onBackClick}></WithdrawWindow>
      )}
      {view === ViewEnum.DEPOSIT && (
        <DepositWindow setView={setView} onBackClick={onBackClick}></DepositWindow>
      )}
      {view === ViewEnum.ABOUT_US && (
        <AboutUsWindow onBackClick={onBackClick}></AboutUsWindow>
      )}
      {view === ViewEnum.TUTORIAL && (
        <TutorialWindow onBackClick={onBackClick}></TutorialWindow>
      )}
      {view === ViewEnum.FINANCES && (
        <FinancesWindow onBackClick={onBackClick}></FinancesWindow>
      )}
      {view === ViewEnum.PROFILE && (
        <ProfileWindow onBackClick={onBackClick}></ProfileWindow>
      )}
      <Navigation/>
    </>
  );
}

export default AccountPage;