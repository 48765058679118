import { Groups, Wallet } from '@mui/icons-material';
import { Box, Card, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const GenericOrderComponent = ({isLoading, orderObject, isSpinOrder, isTeamCommission, isWalletTransaction}) => {
  const theme = useTheme();

  const isNonMobileScreens = useMediaQuery("(min-width: 600px)");

  const user = useSelector((state) => state.user);

  return(
    <Card 
      sx={{
        gridColumn: isNonMobileScreens ? "span 2" : "span 4", 
        borderRadius: "1rem",
        padding: "1rem",
        position: "relative",
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))"
      }}
    >
      <Typography sx={{gridColumn: "span 1", fontSize: "0.7rem"}}>Created at:</Typography>
      <Typography sx={{gridColumn: "span 1", fontSize: "0.7rem", color: theme.palette.primary.main, marginLeft: "-1.5rem"}}>{orderObject.createdAt}</Typography>

      <Typography sx={{gridColumn: "span 1", fontSize: "0.7rem"}}>Reference:</Typography>
      <Typography sx={{gridColumn: "span 1", fontSize: "0.7rem", color: theme.palette.primary.main, marginLeft: "-1.5rem"}}>{orderObject._id}</Typography>

      <Box 
        gridTemplateColumns="repeat(5, minmax(0, 1fr))"
        sx={{
          gridColumn: "span 2",
          borderRadius: "1rem",
          padding: "0.5rem",
          display: "grid",
        }}
      >
        <Box sx={{gridColumn: "span 2", textAlign: "center", margin:"auto"}}>
          {isSpinOrder && (
          <img src={`${apiBaseUrl}/assets/${orderObject.imgReference}.png`} alt={orderObject.imgReference} width="30%" style={{}}/>
          )}
          {isTeamCommission && <Groups style={{fontSize: '64px'}}/>}
          {isWalletTransaction && <Wallet style={{fontSize: '64px'}}/>}
          
        </Box>

        <Box sx={{gridColumn: "span 3", margin: "auto"}}>
          {isSpinOrder && (
            <>
              <Typography sx={{fontWeight: "bold", fontSize: "1.2rem"}}>{orderObject.productName}</Typography>
              <Box sx={{display: "block"}}>
                <Typography sx={{float: "left"}}>{orderObject.productPrice}</Typography>
                <Typography sx={{float: "right", fontSize: "0.7rem"}}>x{parseFloat(orderObject.quantity).toFixed(3)}</Typography>
              </Box>
            </>)}

            {isTeamCommission && (
            <>
              <Typography sx={{fontWeight: "bold", fontSize: "1.2rem"}}>How nice! You have received a team commission!</Typography>
            </>)}

            {isWalletTransaction && (
            <>
              <Typography sx={{fontWeight: "bold", fontSize: "1.2rem"}}>Wallet transaction completed!</Typography>
            </>)}
        </Box>
      </Box>

      {isSpinOrder && (
        <>
          <Typography sx={{gridColumn: "span 1"}}>Total order amount:</Typography>
          <Typography sx={{gridColumn: "span 1", color: theme.palette.primary.main}}>{parseFloat(orderObject.totalOrderAmount).toFixed(3)}</Typography>

          <Typography sx={{gridColumn: "span 1"}}>Commission:</Typography>
          <Typography sx={{gridColumn: "span 1", color: theme.palette.primary.main}}>{parseFloat(orderObject.commission).toFixed(3)}</Typography>

          <Typography sx={{gridColumn: "span 1"}}>Estimated return:</Typography>
          <Typography sx={{gridColumn: "span 1", color: theme.palette.primary.main}}>{parseFloat(orderObject.estimatedReturn).toFixed(3)}</Typography> 

        </>)}

      {isTeamCommission && (
        <>
          <Typography sx={{gridColumn: "span 1"}}>Commission received:</Typography>
          <Typography sx={{gridColumn: "span 1", color: theme.palette.primary.main}}>{parseFloat(orderObject.commission).toFixed(3)}</Typography>
        </>)}

      {isWalletTransaction && (
        <>
          <Typography sx={{gridColumn: "span 1"}}>{orderObject.deposit ? "Added To Balance:" : "Removed from Balance"}</Typography>
          <Typography sx={{gridColumn: "span 1", color: theme.palette.primary.main}}>{parseFloat(orderObject.commission).toFixed(3)}</Typography>
        </>)}
    </Card>
  );
}

export default GenericOrderComponent;