import { Box, Button, useMediaQuery } from "@mui/material";
import { ViewEnum } from "utils/views.constants";

const DepositWithdraw = ({setView}) => {
  const isNonMobileScreen = useMediaQuery("(min-width: 600px)");

  return(
    <>
      <Box
        display="grid"
        gap= {isNonMobileScreen ? "30px" : "10px"}
        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
        height={isNonMobileScreen ? "4rem" : "2rem"}
        sx={{
          "& > div": { gridColumn: isNonMobileScreen ? undefined : "span 2" },
        }}
      >
        <Button variant="contained" onClick={() => setView(ViewEnum.DEPOSIT)}>Deposit</Button>
        <Button variant="contained" onClick={() => setView(ViewEnum.WITHDRAW)}>Withdraw</Button>
      </Box>
    </>
  );
};

export default DepositWithdraw;