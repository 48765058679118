import { Typography, useMediaQuery, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { useEffect, useState } from "react";
import SlotReelv2 from "./SlotReelv2";

const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const SlotMachine = ({toSpin, setToSpin, setShouldCreateOrder, imagesIndexes, isLvlZero}) => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 600px)");
  const [arrayOfImages, setArrayOfImages] = useState([]);
  const [targets, setTargets] = useState([0,0,0])
  
  useEffect(() => {
    if (toSpin) {
      var newTarget0 = Math.floor(Math.random() * imagesIndexes.length);
      var newTarget1 = Math.floor(Math.random() * imagesIndexes.length);
      var newTarget2 = Math.floor(Math.random() * imagesIndexes.length);

      setTargets([newTarget0, newTarget1, newTarget2]);

      setTimeout(() => {
        setToSpin(false);
        setShouldCreateOrder(true);
      },5000);
    }
  }, [toSpin]);

  useEffect(() => {
    setArrayOfImages(imagesIndexes.map(index => `${apiBaseUrl}/assets/slotreel/img${index}.png`));
  }, [imagesIndexes]);

  return(
    isLvlZero ?  
      <Typography
        sx={{
          fontSize: isNonMobileScreens ? "3rem" : "1rem",
          color: theme.palette.primary.main,
          textAlign: "center"
        }}
      >
        { "You need to deposit at least 10USDT to spin!" }
      </Typography> 
      : 
    <FlexBetween
      display="grid"
      gap= {isNonMobileScreens ? "30px" : "10px"}
      gridTemplateColumns="repeat(3, minmax(0, 1fr))"
      height={isNonMobileScreens ? "3rem" : "2rem"}
      sx={{
        "& > div": { gridColumn: isNonMobileScreens ? undefined : "span 1" },
        border: "20px solid #ae6000",
        borderRadius: "10%/60%",
        height: "auto"
      }}
    >
    <SlotReelv2 spin={toSpin} images={arrayOfImages} target={targets[0]}></SlotReelv2>
    <SlotReelv2 SlotReelv2 spin={toSpin} images={arrayOfImages} target={targets[1]}></SlotReelv2>
    <SlotReelv2 spin={toSpin} images={arrayOfImages} target={targets[2]}></SlotReelv2>
    </FlexBetween>
  );
}
  
export default SlotMachine;