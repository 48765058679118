import { Box, Chip, Divider, useMediaQuery, useTheme } from "@mui/material";
import Marquee from "components/Marquee/Marquee";
import WidgetWrapper from "components/WidgetWrapper";
import { useState } from "react";
import { ViewEnum } from "utils/views.constants";
import AboutUsWindow from "widgets/AboutUsWindow/AboutUsWindow";
import AppHeader from "widgets/AppHeader/AppHeader";
import DepositWindow from "widgets/DepositWindow/DepositWindow";
import DepositWithdraw from "widgets/DepositWithdraw/DepositWithdraw";
import EventsWindow from "widgets/EventsWindow/EventsWindow";
import FinancesWindow from "widgets/FinancesWindow/FinancesWindow";
import HomePageBubbleMenu from "widgets/HomePageBubbleMenu/HomePageBubbleMenu";
import InviteWindow from "widgets/InviteWindow/InviteWindow";
import LevelCardsContainer from "widgets/LevelCardsContainer/LevelCardsContainer";
import Navigation from "widgets/Navigation/Navigation";
import Slideshow from "widgets/Slideshow/Slideshow";
import TutorialWindow from "widgets/TutorialWindow/TutorialWindow";
import WithdrawWindow from "widgets/WithdrawWindow/WithdrawWindow";

const HomePage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 600px)");
  const [view, setView] = useState(ViewEnum.HOME)

  const onBackClick = () => {
    setView(ViewEnum.HOME);
  }
  
  return(
    <>
      <AppHeader/>
      {view === ViewEnum.HOME && (
        <Box backgroundColor={theme.palette.background.paper} width="100%" height="100%"
        sx = {{
          overflow: "hidden",
          overflowY: "auto",
          paddingBottom: "8em"
        }}
        >
          <WidgetWrapper sx={{width: isNonMobileScreens ? "85%" : "95%", marginLeft: "auto", marginRight: "auto"}}>
            <Slideshow/>
          </WidgetWrapper>

          <Marquee text="UNLOCK a new level and WIN more money !!!"/>

          <WidgetWrapper sx={{width: isNonMobileScreens ? "70%" : "95%", marginLeft: "auto", marginRight: "auto"}}>
            <DepositWithdraw setView={setView}/>
          </WidgetWrapper>
          <HomePageBubbleMenu setView={setView}/>
          <Divider sx={{margin: "2.6em 0.5rem 0.5rem"}}>
            <Chip label="Levels" />
          </Divider>
          <WidgetWrapper>
            <LevelCardsContainer/>
          </WidgetWrapper>
        </Box>
      )}
      {view === ViewEnum.INVITE && (
        <InviteWindow onBackClick={onBackClick}></InviteWindow>
      )}
      {view === ViewEnum.WITHDRAW && (
        <WithdrawWindow setView={setView} onBackClick={onBackClick}></WithdrawWindow>
      )}
      {view === ViewEnum.DEPOSIT && (
        <DepositWindow setView={setView} onBackClick={onBackClick}></DepositWindow>
      )}
      {view === ViewEnum.ABOUT_US && (
        <AboutUsWindow onBackClick={onBackClick}></AboutUsWindow>
      )}
      {view === ViewEnum.TUTORIAL && (
        <TutorialWindow onBackClick={onBackClick}></TutorialWindow>
      )}
      {view === ViewEnum.EVENTS && (
        <EventsWindow onBackClick={onBackClick}></EventsWindow>
      )}
      {view === ViewEnum.FINANCES && (
        <FinancesWindow onBackClick={onBackClick}></FinancesWindow>
      )}
      <Navigation/>
    </>
  )
}

export default HomePage;