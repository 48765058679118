import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton, Pagination, Skeleton, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import GenericRequestComponent from "components/GenericRequestComponent";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const FinancesWindow = ({onBackClick}) => {

  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 600px)");


  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);

  const axiosHeaders = {headers: {Authorization: "Bearer " + token}}
  const [loading, setLoading] = useState(true);

  const [pageState, setPageState] = useState({
    pageSize: isNonMobileScreens ? 10 : 6,
    noOfPages: 1,
    selectedPage: 1,
    activeTab: 0,
    depositRequestsTabData: null,
    withdrawRequestsTabData: null,
 });

  const handleTabsChange = (event, newValue) => {
    setPageState(prevState => ({ ...prevState, activeTab: newValue, selectedPage: 1 }));

  };

  const handlePageChange = (event, newPage) => {
    setPageState(prevState => ({ ...prevState, selectedPage: newPage }));
  };

  useEffect(() => {
    // Fetch data for each tab when the component mounts
    fetchData(pageState.activeTab);
  }, []);

  useEffect(() => {
    // Fetch data for each tab when the component mounts
    fetchData(pageState.activeTab);
  }, [pageState.activeTab, pageState.selectedPage]);

  const fetchData = (tabIndex) => {
    // Define the API endpoints for each tab
    const endpoints = [
      apiBaseUrl + '/requests/deposits',
      apiBaseUrl + '/requests/withdraws',
    ];

    const tabDataKeys = [
      'depositRequestsTabData',
      'withdrawRequestsTabData',
    ];

    setLoading(true);
    // Make a GET request to the corresponding endpoint
    axios.get(endpoints[tabIndex], 
      { params: {
          page: pageState.selectedPage, 
          size: pageState.pageSize
        },
        ...axiosHeaders
      })
      .then((response) => {
        // Update the state with the fetched data
        const tabDataKey = tabDataKeys[tabIndex]
        setPageState(prevState => ({ ...prevState, [tabDataKey]: response.data.requests, 
          noOfPages: Math.ceil(response.data.totalCount / prevState.pageSize) === 0 ? 1 : Math.ceil(response.data.totalCount / prevState.pageSize)}));
        setLoading(false);
      })
      .catch((error) => {
        console.error(`Error fetching data for tab${tabIndex + 1}: ${error.message}`);
      });
  }

  return(
    <>
      <Box backgroundColor={theme.palette.background.paper} width="100%" height="100%"
      sx = {{
        overflow: "hidden",
        overflowY: "auto",
        paddingLeft: "1em",
        paddingRight: "1em",
        paddingBottom: "8em"
      }}
      >
        <IconButton
          edge="start"
          color="primary"
          aria-label="back"
          onClick={onBackClick}
        >
          <ArrowBack sx={{fontSize: "30px", margin: "10px"}}/>
        </IconButton>

        <Typography sx={{color: theme.palette.primary.main, fontSize: "1.5rem", textAlign: "center", marginBottom: "1rem"}}>Finances</Typography>

        <Tabs value={pageState.activeTab} onChange={handleTabsChange} centered variant="fullWidth">
          <Tab label="Deposit Requests" />
          <Tab label="Withdraw Requests" />
        </Tabs>
        <Box
          display="grid"
          gap= {isNonMobileScreens ? "30px" : "10px"}
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            padding: "1rem"
          }}
        >
          {pageState.activeTab === 0 && pageState.depositRequestsTabData && (
            loading ? (
              <Skeleton variant="rounded" height= "100%" sx={{gridColumn: "span 4"}}/>
            ) :
              pageState.depositRequestsTabData.map(request => (
                <GenericRequestComponent requestObject={request} />
              ))
            )
          }
          {pageState.activeTab === 1 && pageState.withdrawRequestsTabData && (
            loading ? (
              <Skeleton variant="rounded" height= "100%" sx={{gridColumn: "span 4"}}/>
            ) :
              pageState.withdrawRequestsTabData.map(request => (
                <GenericRequestComponent requestObject={request} />
              ))
            )
          }
        </Box>
        <Pagination count={pageState.noOfPages} color="secondary" 
          page={pageState.selectedPage}
          onChange={handlePageChange}
          sx = {{ 
            display: "flex", 
            justifyContent: "center",
            marginBottom: "15vh"
          }}/>
{/*         
        <Divider sx={{margin: "1.2em 0.5em 1.2rem 0.5rem"}}>
          <Chip label="Withdraw request form" />
        </Divider> */}
      </Box>
    </>
  );
}

export default FinancesWindow;