import { ArrowBack, School } from "@mui/icons-material";
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, Chip, Divider, IconButton, Snackbar, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ViewEnum } from "utils/views.constants";
import { depositRequestValidationSchema } from "./validation";

const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const DepositWindow = ({onBackClick, setView}) => {

  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const isNonMobileScreen = useMediaQuery("(min-width: 600px)");


  // const [activeTab, setActiveTab] = useState(0);
  const [walletToDeposit, setWalletToDeposit] = useState({});

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    position: {
      vertical: 'top',
      horizontal: 'right',
    },
    snackMessage: '',
    severity: '',
  });


  const axiosHeaders = {headers: {Authorization: "Bearer " + token}}

  // const handleTabsChange = (event, newValue) => {
  //   setActiveTab(newValue);
  // };

  useEffect(() => {
    axios.get(apiBaseUrl + "/wallet/random", axiosHeaders)
    .then(res => {
      setWalletToDeposit(res.data);
    })
    .catch(function (error) {
      console.error(error);
    });

  }, []);

  const initialValuesDepositRequest = {
    transactionId: "",
  };

  const handleDepositRequest = async (values, onSubmitProps) => {
    await createDepositRequest(values, onSubmitProps);
  };

  const createDepositRequest = async(values, onSubmitProps) => {
    setLoading(true);
    const body = {
      userId: user._id,
      adminWalletId: walletToDeposit.walletId,
      transactionId : values.transactionId
    }
    axios.post(apiBaseUrl + "/requests/deposit", body, axiosHeaders)
    .then(res => {
      onSubmitProps.resetForm();
      handleSnackbarOpen("Request created succesfully!", 'success');
      setLoading(false);
    })
    .catch(function (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.status === 400) {
          onSubmitProps.setFieldError(error.response.data.field, error.response.data.error)
          handleSnackbarOpen(error.response.data.error, 'error');
        }
      } else if (error.request) {
        handleSnackbarOpen("Error request: " + error.request.responseText, 'error');

      } else {
        handleSnackbarOpen('Error ' + error.message, 'error');
      }
    });
  }

  const handleSnackbarOpen = (newSnackMessage, severity) => {
    setSnackbarState({ ...snackbarState, open: true, snackMessage: newSnackMessage, severity: severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState({ ...snackbarState, open: false });
  };

  return(
    <>
      <Box backgroundColor={theme.palette.background.paper} width="100%" height="100%"
      sx = {{
        overflow: "hidden",
        overflowY: "auto",
        paddingBottom: "8em"
      }}
      >
        <Snackbar
        anchorOrigin={snackbarState.position}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        autoHideDuration={6000}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarState.severity} sx={{ width: '100%' }}>
            {snackbarState.snackMessage}
          </Alert>
        </Snackbar>
        {/* <Tabs value={activeTab} onChange={handleTabsChange} centered variant="fullWidth">
          <Tab label="Crypto Deposit" />
          <Tab label="Cash Deposit" />
        </Tabs> */}

        <IconButton
          edge="start"
          color="primary"
          aria-label="back"
          onClick={onBackClick}
        >
          <ArrowBack sx={{fontSize: "30px", margin: "10px"}}/>
        </IconButton>

        <Typography sx={{fontSize: "1rem", textAlign: "center", marginBottom: "1rem"}}>Deposit directly to our USDT Wallet!</Typography>
        <Typography sx={{color: theme.palette.primary.main, fontSize: "1.1rem", textAlign: "center"}}>TRC20 USDT Wallet ID:</Typography>
        <Typography sx={{color: theme.palette.primary.main, fontSize: "1.1rem", textAlign: "center", marginBottom: "1rem"}}>{walletToDeposit.walletId}
        </Typography>
        <Typography sx={{fontSize: "1rem", textAlign: "center", marginBottom: "1rem"}}>Use your own crypto wallet or follow tutorial on how to do it by <b>CARD</b>!</Typography>
        <div style={{ width: "100%", justifyContent: "center", display: "flex", marginBottom: "1rem" }}>
          <Button variant="contained" onClick={() => setView(ViewEnum.TUTORIAL)}><School></School> Tutorial</Button>
        </div>
        <Typography sx={{fontSize: "1rem", textAlign: "center"}}>Make sure to fill in the below with the TxID!</Typography>
        
        <Divider sx={{margin: "1.2em 0.5em 1.2rem 0.5rem"}}>
          <Chip label="Deposit request form" />
        </Divider>

        <Formik
        onSubmit={ handleDepositRequest }
        initialValues={ initialValuesDepositRequest }
        validationSchema={ depositRequestValidationSchema }
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap= {isNonMobileScreen ? "30px" : "10px"}
                gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                sx={{
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  display: "grid"
                }}
              >
                <TextField
                  label="TransactionId(TxID)"
                  type="string"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.transactionId}
                  name="transactionId"
                  error={Boolean(touched.transactionId) && Boolean(errors.transactionId)}
                  helperText={touched.transactionId && errors.transactionId}
                  size={isNonMobileScreen ? "normal" : "small" }
                  sx={{ gridColumn: "span 2" }}
                />

                <LoadingButton
                  fullWidth
                  type="submit"
                  loading={loading}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.background.paper,
                    gridColumn: "span 2",
                    "&:hover": { 
                      backgroundColor: theme.palette.secondary.main,
                    },
                  }}
                >
                  { "Submit" }
                </LoadingButton>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
}

export default DepositWindow;