import { Alert, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Slide, Snackbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import OrderComponent from "components/OrderComponent";
import WidgetWrapper from "components/WidgetWrapper";
import { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ViewEnum } from "utils/views.constants";
import AppHeader from "widgets/AppHeader/AppHeader";
import DepositWindow from "widgets/DepositWindow/DepositWindow";
import Navigation from "widgets/Navigation/Navigation";
import SlotMachine from "widgets/SlotMachine/SlotMachine";
import TutorialWindow from "widgets/TutorialWindow/TutorialWindow";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const SpinPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 600px)");

  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);

  const isLvlZero = user.levelId === 0;

  const [snackbarState, setSnackbarState] = useState({
      open: false,
      position: {
        vertical: 'top',
        horizontal: 'right',
      },
      snackMessage: '',
      severity: '',
    });
  const [toSpin, setToSpin] = useState(false);
  const [shouldCreateOrder, setShouldCreateOrder] = useState(false);
  const [openCreateOrderModal, setOpenCreateOrderModal] = useState(false);
  const [orderObject, setOrderObject] = useState({});
  const [statisticsObject, setStatisticsObject] = useState({});
  const [orderLoading, setOrderLoading] = useState(false);
  const [view, setView] = useState(ViewEnum.SPIN)

  const [imagesIndexes, setImagesIndexes] = useState([1,2,3,4,5])
  
  const reelImageNumbers = [1,2,3,4,5,6];

  const axiosHeaders = {headers: {Authorization: "Bearer " + token}}

  const onBackClick = () => {
    setView(ViewEnum.SPIN);
  }

  const createOrder = async() => {
    setOrderLoading(true);
    axios.post(apiBaseUrl + "/orders/createOrder", {userId: user._id}, axiosHeaders)
    .then(res => {
      setOrderObject({
        orderTime: res.data.order.createdAt,
        orderReference: res.data.order._id,
        productImgReference: res.data.product.imgReference,
        productName: res.data.product.fullTitle,
        productPrice: res.data.product.price,
        productQuantity: res.data.order.quantity,
        totalOrderAmount: res.data.order.totalOrderAmount,
        commission: res.data.order.commission,
        estimatedReturn: res.data.order.estimatedReturn,
        orderCompleted: res.data.order.completed
      })

      setStatisticsObject(res.data.statistics)
      setOpenCreateOrderModal(true);
      setOrderLoading(false);
    })
    .catch(function(err) {
      if (err.response) {
        console.log(err)
        // handleSnackbarOpen(error.response.data.error, 'error');
        if (err.response.status === 400) {
          handleSnackbarOpen(err.response.data.error, 'error');
        }
      } else if (err.request) {
        handleSnackbarOpen("Error request: " + JSON.stringify(err), 'error');
      } else {
        handleSnackbarOpen('Error ' + err.message, 'error');
      }
    });
  }

  useEffect(() => {
    if (shouldCreateOrder) {
      setShouldCreateOrder(false);

      createOrder();
    }
  },[shouldCreateOrder])

  useEffect(() => {
    axios.get(apiBaseUrl + "/statistics/" + user._id, axiosHeaders)
    .then(res => {
      setStatisticsObject(res.data)
    })
    .catch(function(err) {
      if (err.response) {
        if (err.response.status === 400) {
          handleSnackbarOpen(err.response.data.error, 'error');
        }
      } else if (err.request) {
        handleSnackbarOpen("Error request: " + err.request, 'error');

      } else {
        handleSnackbarOpen('Error ' + err.message, 'error');
      }
    });
  }, [])

  const handleClose = () => {
    setOpenCreateOrderModal(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleSnackbarOpen = (newSnackMessage, severity) => {
    setSnackbarState({ ...snackbarState, open: true, snackMessage: newSnackMessage, severity: severity });
  };

  return(
    <>
      <AppHeader/>
      {view === ViewEnum.SPIN && (
        <>
        <Dialog
          open={openCreateOrderModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Creating Oder!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Congratulations, an order will be placed!
            </DialogContentText>
            <OrderComponent sx={{marginTop:"1rem"}} orderObject={orderObject} userLevel={user.levelId} isLoading={orderLoading}></OrderComponent>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
        <Box backgroundColor={theme.palette.background.paper} width="100%" height="100%"
        sx = {{
          overflow: "hidden",
          overflowY: "auto",
          paddingBottom: "8em"
        }}
        >
          <Snackbar
            anchorOrigin={snackbarState.position}
            open={snackbarState.open}
            onClose={handleSnackbarClose}
            autoHideDuration={6000}
          >
            <Alert onClose={handleSnackbarClose} severity={snackbarState.severity} sx={{ width: '100%' }}>
              {snackbarState.snackMessage}
            </Alert>
          </Snackbar>
          <WidgetWrapper sx={{width: isNonMobileScreens ? "85%" : "95%", marginLeft: "auto", marginRight: "auto"}}>
            <SlotMachine isLvlZero={isLvlZero} toSpin={toSpin} setToSpin={setToSpin} setShouldCreateOrder={setShouldCreateOrder} imagesIndexes={imagesIndexes}/>
          </WidgetWrapper>

          <WidgetWrapper sx={{width: isNonMobileScreens ? "85%" : "95%", marginLeft: "auto", marginRight: "auto"}}>
          <Box
              display="grid"
              gap= {isNonMobileScreens ? "30px" : "10px"}
              gridTemplateColumns="repeat(2, minmax(0, 1fr))"
              height={isNonMobileScreens ? "3rem" : "2rem"}
              sx={{
                "& > div": { gridColumn: isNonMobileScreens ? undefined : "span 2" },
              }}
            >
              <Button variant="contained" onClick={() => setView(ViewEnum.DEPOSIT)}>Deposit Upgrade</Button>
              <Button variant="contained" 
                onClick={()=>{
                  setImagesIndexes(reelImageNumbers)
                  setToSpin(true)
                }}
                disabled={toSpin || isLvlZero}
              >
                Spin
              </Button>
            </Box>
          </WidgetWrapper>

          <WidgetWrapper sx={{width: isNonMobileScreens ? "85%" : "95%", marginLeft: "auto", marginRight: "auto", marginBottom: "15vh"}}>
            <Card
                sx={{
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                  display: "grid"
                }}
              >
                <Typography sx={{fontWeight: "bold", fontSize: "1.2rem", gridColumn: "span 2", justifyContent: "center", display: "flex"}}>Today's Achievements</Typography>
                <Paper elevation={3} sx={{
                  gridColumn: "span 1",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  display: 'flex',
                  justifyContent: 'center',   
                  alignItems: 'center',       
                  flexDirection: 'column',
                  margin: "0.5rem"
                }}>
                  <Typography sx={{fontSize: "0.8rem"}}>Today Commission:</Typography>
                  <Typography sx={{fontSize: "1.5rem", color: theme.palette.primary.main}}>{parseFloat(statisticsObject.todayCommission).toFixed(3)}</Typography>
                </Paper>
                <Paper elevation={3} sx={{
                  gridColumn: "span 1",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  display: 'flex',
                  justifyContent: 'center',   
                  alignItems: 'center',       
                  flexDirection: 'column',
                  margin: "0.5rem"
                }}>
                  <Typography sx={{fontSize: "0.8rem"}}>Available balance:</Typography>
                  <Typography sx={{fontSize: "1.5rem", color: theme.palette.primary.main}}>{parseFloat(statisticsObject.availableBalance).toFixed(3)}</Typography>
                </Paper>
                <Paper elevation={3} sx={{
                  gridColumn: "span 1",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  display: 'flex',
                  justifyContent: 'center',   
                  alignItems: 'center',       
                  flexDirection: 'column',
                  margin: "0.5rem"
                }}>
                  <Typography sx={{fontSize: "0.8rem"}}>Today Team Commission:</Typography>
                  <Typography sx={{fontSize: "1.5rem", color: theme.palette.primary.main}}>{parseFloat(statisticsObject.todayTeamCommission).toFixed(3)}</Typography>
                </Paper>
                <Paper elevation={3} sx={{
                  gridColumn: "span 1",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  display: 'flex',
                  justifyContent: 'center',   
                  alignItems: 'center',       
                  flexDirection: 'column',
                  margin: "0.5rem"
                }}>
                  <Typography sx={{fontSize: "0.8rem"}}>Orders left:</Typography>
                  <Typography sx={{fontSize: "1.5rem", color: theme.palette.primary.main}}>{statisticsObject.ordersLeft}</Typography>
                </Paper>
                <Paper elevation={3} sx={{
                  gridColumn: "span 1",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  display: 'flex',
                  justifyContent: 'center',   
                  alignItems: 'center',       
                  flexDirection: 'column',
                  margin: "0.5rem"
                }}>
                  <Typography sx={{fontSize: "0.8rem"}}>Yesterday commission:</Typography>
                  <Typography sx={{fontSize: "1.5rem", color: theme.palette.primary.main}}>{parseFloat(statisticsObject.yesterdayCommission).toFixed(3)}</Typography>
                </Paper>
                <Paper elevation={3} sx={{
                  gridColumn: "span 1",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  display: 'flex',
                  justifyContent: 'center',   
                  alignItems: 'center',       
                  flexDirection: 'column',
                  margin: "0.5rem"
                }}>
                  <Typography sx={{fontSize: "0.8rem"}}>Yesterday Team commission:</Typography>
                  <Typography sx={{fontSize: "1.5rem", color: theme.palette.primary.main}}>{parseFloat(statisticsObject.yesterdayTeamCommission).toFixed(3)}</Typography>
                </Paper>
            </Card>
          </WidgetWrapper>
        </Box>
        </>
      )}
      {view === ViewEnum.DEPOSIT && (
        <DepositWindow setView={setView} onBackClick={onBackClick}></DepositWindow>
      )}
      {view === ViewEnum.TUTORIAL && (
        <TutorialWindow onBackClick={onBackClick}></TutorialWindow>
      )}
      <Navigation setToSpin={isLvlZero ? (x) => {console.log('Need to deposit at least 10')} : setToSpin}/>
    </>
  )
}

export default SpinPage;