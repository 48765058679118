import * as Yup from "yup";
import YupPassword from "yup-password";

YupPassword(Yup);

export const registerValidationSchema = Yup.object().shape({
  username: Yup.string()
    .required("Username is required")
    .min(8, "Username length should be at least 8 characters.")
    .max(20, "Username cannot exceed more than 20 characters."),
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Password length should be at least 8 characters.")
    .minLowercase(1, "Password must contain at least 1 lower case letter.")
    .minUppercase(1, "Password must contain at least 1 upper case letter.")
    .minNumbers(1, "Password must contain at least 1 number.")
    .minSymbols(1, "Password must contain at least 1 special character."),
  confirm_password: Yup.string()
    .required("Confirm Password is required.")
    .oneOf([Yup.ref("password")], "Passwords do not match."),
  email: Yup.string()
    .required("Email is required.")
    .email("Please enter a valid email address."),
  invite_code: Yup.string()
    .required("Invite code is required.")
    .min(8, "Invite code is too short."),
  pin: Yup.string()
    .required("PIN is required.")
    .min(6, "PIN should be exactly 6 digits.")
    .max(6, "PIN should be exactly 6 digits.")
    .minNumbers(6, "PIN should be exactly 6 digits."),
});

export const resetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Password length should be at least 8 characters.")
    .minLowercase(1, "Password must contain at least 1 lower case letter.")
    .minUppercase(1, "Password must contain at least 1 upper case letter.")
    .minNumbers(1, "Password must contain at least 1 number.")
    .minSymbols(1, "Password must contain at least 1 special character."),
  confirm_password: Yup.string()
    .required("Confirm Password is required.")
    .oneOf([Yup.ref("password")], "Passwords do not match."),
  email: Yup.string()
    .required("Email is required.")
    .email("Please enter a valid email address."),
  pin: Yup.string()
    .required("PIN is required.")
    .min(6, "PIN should be exactly 6 digits.")
    .max(6, "PIN should be exactly 6 digits.")
    .minNumbers(6, "PIN should be exactly 6 digits."),
});

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required.")
    .email("Please enter a valid email address."),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password length should be at least 8 characters")
    .minLowercase(1, "Password must contain at least 1 lower case letter")
    .minUppercase(1, "Password must contain at least 1 upper case letter")
    .minNumbers(1, "Password must contain at least 1 number")
    .minSymbols(1, "Password must contain at least 1 special character"),
});

export const forgottenValidation = Yup.object().shape({
  forgotten_email: Yup.string()
    .required("Email is required.")
    .email("Please enter a valid email address."),
});
