import { AccountBalanceWallet, NewReleases, NotListedLocation, PersonAdd, School } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import BubbleMenuButton from "components/BubbleMenuButton/BubbleMenuButton";
import FlexBetween from "components/FlexBetween";
import { ViewEnum } from "utils/views.constants";

const HomePageBubbleMenu = ({setView}) => {
  const isNonMobileScreens = useMediaQuery("(min-width: 600px)");

  const handleClick = (view) => {
    setView(view);
  }

  return (
    <FlexBetween 
      sx={{
        marginTop: "0.75rem", 
        paddingRight: isNonMobileScreens ? "30%" : "5%", 
        paddingLeft: isNonMobileScreens ? "30%" : "5%",
        }}
    >
      <BubbleMenuButton
        icon = {<NotListedLocation color='primary' sx={{fontSize: "40px"}}/>}
        label = "About Us"
        event = {() => handleClick(ViewEnum.ABOUT_US)}
      />
      <BubbleMenuButton
        icon = {<School color='primary' sx={{fontSize: "40px"}}/>}
        label = "Beginners Tutorial"
        event = {() => handleClick(ViewEnum.TUTORIAL)}
      />
      <BubbleMenuButton
        icon = {<PersonAdd color='primary' sx={{fontSize: "40px"}}/>}
        label = "Invite"
        event = {() => handleClick(ViewEnum.INVITE)}
      />
      <BubbleMenuButton
        icon = {<NewReleases color='primary' sx={{fontSize: "40px"}}/>}
        label = "VIP Events"
        event = {() => handleClick(ViewEnum.EVENTS)}
      />
      <BubbleMenuButton
        icon = {<AccountBalanceWallet color='primary' sx={{fontSize: "40px"}}/>}
        label = "Finances"
        event = {() => handleClick(ViewEnum.FINANCES)}
      />
    </FlexBetween>
  );
}

export default HomePageBubbleMenu;