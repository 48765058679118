import { ArrowBack, School } from "@mui/icons-material";
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, Chip, Divider, IconButton, Snackbar, Tab, Tabs, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import { Formik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ViewEnum } from "utils/views.constants";
import { withdrawCardRequestValidationSchema, withdrawCryptoRequestValidationSchema } from "./validation";

const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const WithdrawWindow = ({onBackClick, setView}) => {

  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const [tabsState, setTabsState] = useState({
    activeTab: 0
  })
  const isNonMobileScreen = useMediaQuery("(min-width: 600px)");


  const [snackbarState, setSnackbarState] = useState({
    open: false,
    position: {
      vertical: 'top',
      horizontal: 'right',
    },
    snackMessage: '',
    severity: '',
  });


  const axiosHeaders = {headers: {Authorization: "Bearer " + token}}

  // const handleTabsChange = (event, newValue) => {
  //   setActiveTab(newValue);
  // };

  const initialWithdrawCryptoRequest = {
    walletId: "",
    amount: 0,
    type: "WITHDRAW_CRYPTO"
  };

  const initialWithdrawCardRequest = {
    iban: "",
    nameOnCard: "",
    amount: 0,
    type: "WITHDRAW_CARD"
  };

  const handleWithdrawRequest = async (values, onSubmitProps) => {
    await createWithdrawRequest(values, onSubmitProps);
  };

  const createWithdrawRequest = async(values, onSubmitProps) => {
    setLoading(true);
    const body = values.type === "WITHDRAW_CRYPTO" ? {
      userId: user._id,
      userWalletId: values.walletId,
      amount: values.amount,
      type: values.type,
    } : {
      userId: user._id,
      iban: values.iban,
      type: values.type,
      nameOnCard: values.nameOnCard,
      amount: values.amount,
    };
    axios.post(apiBaseUrl + "/requests/withdraw/" + values.type, body, axiosHeaders)
    .then(res => {
      onSubmitProps.resetForm();
      handleSnackbarOpen("Request created succesfully!", 'success');
      setLoading(false);
    })
    .catch(function (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.status === 400) {
          onSubmitProps.setFieldError(error.response.data.field, error.response.data.error)
          handleSnackbarOpen(error.response.data.error, 'error');
        }
      } else if (error.request) {
        handleSnackbarOpen("Error request: " + error.request.responseText, 'error');

      } else {
        handleSnackbarOpen('Error ' + error.message, 'error');
      }
    });
  }

  const handleSnackbarOpen = (newSnackMessage, severity) => {
    setSnackbarState({ ...snackbarState, open: true, snackMessage: newSnackMessage, severity: severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleTabsChange = (event, newValue) => {
    setTabsState(prevState => ({ ...prevState, activeTab: newValue }));
  };

  return(
    <>
      <Box backgroundColor={theme.palette.background.paper} width="100%" height="100%"
      sx = {{
        overflow: "hidden",
        overflowY: "auto",
        paddingBottom: "8em"
      }}
      >
        <Snackbar
        anchorOrigin={snackbarState.position}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        autoHideDuration={6000}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarState.severity} sx={{ width: '100%' }}>
            {snackbarState.snackMessage}
          </Alert>
        </Snackbar>

        <IconButton
          edge="start"
          color="primary"
          aria-label="back"
          onClick={onBackClick}
        >
          <ArrowBack sx={{fontSize: "30px", margin: "10px"}}/>
        </IconButton>

        <Typography sx={{fontSize: "1rem", textAlign: "center", marginBottom: "1rem"}}>Withdraw directly to your USDT Wallet or Card!</Typography>
        <Typography sx={{fontSize: "1rem", textAlign: "center", marginBottom: "1rem"}}>In doubt? Check the tutorial!</Typography>
        <div style={{ width: "100%", justifyContent: "center", display: "flex", marginBottom: "1rem" }}>
          <Button variant="contained" onClick={() => setView(ViewEnum.TUTORIAL)}><School></School> Tutorial</Button>
        </div>
        <Typography sx={{fontSize: "1rem", textAlign: "center"}}>Make sure to fill in the below form!</Typography>
        
        <Divider sx={{margin: "1.2em 0.5em 1.2rem 0.5rem"}}>
          <Chip label="Withdraw request form" />
        </Divider>

        <Tabs sx={{paddingBottom: "1em"}} value={tabsState.activeTab} onChange={handleTabsChange} centered variant="fullWidth">
          <Tab label="Crypto Withdraw" />
          <Tab label="Card Withdraw" />
        </Tabs>

        {tabsState.activeTab === 0 && (
          <Formik
            onSubmit={ handleWithdrawRequest }
            initialValues={ initialWithdrawCryptoRequest }
            validationSchema={ withdrawCryptoRequestValidationSchema }
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              resetForm,
            }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap= {isNonMobileScreen ? "30px" : "10px"}
                gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                sx={{
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  display: "grid"
                }}
              >
                <TextField
                  label="Wallet ID"
                  type="string"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.walletId}
                  name="walletId"
                  error={Boolean(touched.walletId) && Boolean(errors.walletId)}
                  helperText={touched.walletId && errors.walletId}
                  size={isNonMobileScreen ? "normal" : "small" }
                  sx={{ gridColumn: "span 2" }}
                />

              <TextField
                label="Amount"
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.amount}
                name="amount"
                error={Boolean(touched.amount) && Boolean(errors.amount)}
                helperText={touched.amount && errors.amount}
                size={isNonMobileScreen ? "normal" : "small" }
                sx={{ gridColumn: "span 2" }}
              />

                <LoadingButton
                  fullWidth
                  type="submit"
                  loading={loading}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.background.paper,
                    gridColumn: "span 2",
                    "&:hover": { 
                      backgroundColor: theme.palette.secondary.main,
                    },
                  }}
                >
                  { "Submit" }
                </LoadingButton>
              </Box>
            </form>
          )}
          </Formik>
          )
        }

        {tabsState.activeTab === 1 && (
          <Formik
            onSubmit={ handleWithdrawRequest }
            initialValues={ initialWithdrawCardRequest }
            validationSchema={ withdrawCardRequestValidationSchema }
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              resetForm,
            }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap= {isNonMobileScreen ? "30px" : "10px"}
                gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                sx={{
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  display: "grid"
                }}
              >
                <TextField
                  label="Card IBAN"
                  type="string"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.iban}
                  name="iban"
                  error={Boolean(touched.iban) && Boolean(errors.iban)}
                  helperText={touched.iban && errors.iban}
                  size={isNonMobileScreen ? "normal" : "small" }
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  label="Name on Card"
                  type="string"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.nameOnCard}
                  name="nameOnCard"
                  error={Boolean(touched.nameOnCard) && Boolean(errors.nameOnCard)}
                  helperText={touched.nameOnCard && errors.nameOnCard}
                  size={isNonMobileScreen ? "normal" : "small" }
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  label="Amount"
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.amount}
                  name="amount"
                  error={Boolean(touched.amount) && Boolean(errors.amount)}
                  helperText={touched.amount && errors.amount}
                  size={isNonMobileScreen ? "normal" : "small" }
                  sx={{ gridColumn: "span 2" }}
                />

                <LoadingButton
                  fullWidth
                  type="submit"
                  loading={loading}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.background.paper,
                    gridColumn: "span 2",
                    "&:hover": { 
                      backgroundColor: theme.palette.secondary.main,
                    },
                  }}
                >
                  { "Submit" }
                </LoadingButton>
              </Box>
            </form>
          )}
          </Formik>
          )
        }
      </Box>
    </>
  );
}

export default WithdrawWindow;