import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton, useTheme } from "@mui/material";
import UserWidget from "widgets/UserWidget/UserWidget";

const ProfileWindow = ({onBackClick}) => {

  const theme = useTheme();

  return(
    <>
      <Box backgroundColor={theme.palette.background.paper} width="100%" height="100%"
      sx = {{
        overflow: "hidden",
        overflowY: "auto",
        paddingLeft: "1em",
        paddingRight: "1em",
        paddingBottom: "8em"
      }}
      >
        <IconButton
          edge="start"
          color="primary"
          aria-label="back"
          onClick={onBackClick}
        >
          <ArrowBack sx={{fontSize: "30px", margin: "10px"}}/>
        </IconButton>

        <UserWidget 
            sx = {{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justigyContent: "center",
              width: "100%",
            }}
          />

      </Box>
    </>
  );
}

export default ProfileWindow;