import { ArrowBack } from "@mui/icons-material";
import { LoadingButton } from '@mui/lab';
import { Box, Chip, Divider, IconButton, Snackbar, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import axios from "axios";
import { Formik } from "formik";
import { forwardRef, useState } from "react";
import { useSelector } from "react-redux";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const UserBalanceAdminWindow = ({onBackClick, userToModify}) => {

  const theme = useTheme();
  const isNonMobileScreen = useMediaQuery("(min-width: 600px)");
  // const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  
  const axiosHeaders = {headers: {Authorization: "Bearer " + token}}

  const [loading, setLoading] = useState(false);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    position: {
      vertical: 'top',
      horizontal: 'right',
    },
    snackMessage: '',
    severity: '',
  });

  const updateBalance = (newBalance) => {
    userToModify.balance = newBalance;
  }

  // WITHDRAW FLOW

  const initialValuesWithdraw = {
    withdrawAmount: 0,
  };

  const handleWithdrawSubmit = async (values, onSubmitProps) => {
    await withdraw(values, onSubmitProps);
  };

  const withdraw = async(values, onSubmitProps) => {
    setLoading(true);
    const body = {
      userId: userToModify.id,
      value: values.withdrawAmount
    }
    axios.post(apiBaseUrl + "/balance/remove", body, axiosHeaders)
    .then(res => {
      onSubmitProps.resetForm();
      handleSnackbarOpen("Balance updated succesfully!", 'success');
      updateBalance(res.data.newBalance);
      setLoading(false);
    })
    .catch(function (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.status === 400) {
          onSubmitProps.setFieldError(error.response.data.field, error.response.data.error)
          handleSnackbarOpen(error.response.data.error, 'error');
        }
      } else if (error.request) {
        handleSnackbarOpen("Error request: " + error.request.responseText, 'error');

      } else {
        handleSnackbarOpen('Error ' + error.message, 'error');
      }
    });
  }

  // DEPOSIT FLOW

  const initialValuesDeposit = {
    depositAmount: 0,
  };

  const handleDepositSubmit = async (values, onSubmitProps) => {
    await deposit(values, onSubmitProps);
  };

  const deposit = async(values, onSubmitProps) => {
    setLoading(true);
    const body = {
      userId: userToModify.id,
      value: values.depositAmount
    }
    axios.post(apiBaseUrl + "/balance/add", body, axiosHeaders)
    .then(res => {
      onSubmitProps.resetForm();
      handleSnackbarOpen("Balance updated succesfully!", 'success');
      updateBalance(res.data.newBalance);
      setLoading(false);
    })
    .catch(function (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.status === 400) {
          onSubmitProps.setFieldError(error.response.data.field, error.response.data.error)
          handleSnackbarOpen(error.response.data.error, 'error');
        }
      } else if (error.request) {
        console.log(error);
        handleSnackbarOpen("Error request: " + error.request.responseText, 'error');

      } else {
        handleSnackbarOpen('Error ' + error.message, 'error');
      }
    });
  }
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleSnackbarOpen = (newSnackMessage, severity) => {
    setSnackbarState({ ...snackbarState, open: true, snackMessage: newSnackMessage, severity: severity });
  };

  
  return(
    <Box backgroundColor={theme.palette.background.paper} width="100%" height="100%"
    sx = {{
      overflow: "hidden",
      overflowY: "auto",
      paddingBottom: "8em"
    }}
    >
      <Snackbar
        anchorOrigin={snackbarState.position}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        autoHideDuration={6000}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarState.severity} sx={{ width: '100%' }}>
          {snackbarState.snackMessage}
        </Alert>
      </Snackbar>

      <IconButton
        edge="start"
        color="primary"
        aria-label="back"
        onClick={onBackClick}
      >
        <ArrowBack sx={{fontSize: "30px", margin: "10px"}}/>
      </IconButton> 

      <Typography sx={{fontSize: "3rem", textAlign: "center", marginBottom: "1rem"}}>Add Or Remove Funds from User Balance</Typography>

      <Divider sx={{margin: "2.6em 0.5rem 0.5rem"}}>
        <Chip label="Username" />
      </Divider>
      <Typography sx={{color: theme.palette.primary.main, fontSize: "2.3rem", textAlign: "center"}}>{userToModify.username}
      </Typography>

      <Divider sx={{margin: "0.5em 0.5rem 0.5rem"}}>
        <Chip label="Balance" />
      </Divider>
      <Typography sx={{color: theme.palette.primary.main, fontSize: "1.5rem", textAlign: "center"}}>{userToModify.balance}
      </Typography>

      <Divider sx={{margin: "0.5em 0.5rem 0.5rem"}}>
        <Chip label="Deposit" />
      </Divider>
      <Formik
        onSubmit={ handleDepositSubmit }
        initialValues={ initialValuesDeposit }
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap= {isNonMobileScreen ? "30px" : "10px"}
              gridTemplateColumns="repeat(2, minmax(0, 1fr))"
              sx={{
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            >
              <TextField
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.depositAmount}
                name="depositAmount"
                error={Boolean(touched.depositAmount) && Boolean(errors.depositAmount)}
                helperText={touched.depositAmount && errors.depositAmount}
                size={isNonMobileScreen ? "normal" : "small" }
                sx={{ gridColumn: "span 1" }}
              />

              <LoadingButton
                fullWidth
                type="submit"
                loading={loading}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.background.paper,
                  "&:hover": { 
                    backgroundColor: theme.palette.secondary.main,
                  },
                }}
              >
                { "Submit" }
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
      
      <Divider sx={{margin: "0.5em 0.5rem 0.5rem"}}>
        <Chip label="Withdraw" />
      </Divider>
      <Formik
        onSubmit={ handleWithdrawSubmit }
        initialValues={ initialValuesWithdraw }
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap= {isNonMobileScreen ? "30px" : "10px"}
              gridTemplateColumns="repeat(2, minmax(0, 1fr))"
              sx={{
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            >
              <TextField
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.withdrawAmount}
                name="withdrawAmount"
                error={Boolean(touched.withdrawAmount) && Boolean(errors.withdrawAmount)}
                helperText={touched.withdrawAmount && errors.withdrawAmount}
                size={isNonMobileScreen ? "normal" : "small" }
                sx={{ gridColumn: "span 1" }}
              />

              <LoadingButton
                fullWidth
                type="submit"
                loading={loading}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.background.paper,
                  "&:hover": { 
                    backgroundColor: theme.palette.secondary.main,
                  },
                }}
              >
                { "Submit" }
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default UserBalanceAdminWindow;