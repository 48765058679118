import { Box, Card, Skeleton, Typography, useTheme } from '@mui/material';

const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const OrderComponent = ({isLoading, orderObject, userLevel, isSpinOrder, isTeamCommission, isWalletTransaction}) => {
  const theme = useTheme();

  return(
    (isLoading ? (             
       <Skeleton variant="rounded" height= "100%" sx={{gridColumn: "span 4"}}/>
    ) : (
    <Card
      gridTemplateColumns="repeat(2, minmax(0, 1fr))"
 
      sx={{
        // gridColumn: isNonMobileScreens ? "span 2" : "span 4", 
        backgroundColor: theme.palette.background.paper,
        borderRadius: "1rem",
        padding: "0.5rem",
        position: "relative",
        marginTop: "1rem",
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))"
      }}
    >
      <Typography sx={{gridColumn: "span 1", fontSize: "0.7rem"}}>Order time:</Typography>
      <Typography sx={{gridColumn: "span 1", fontSize: "0.7rem", color: theme.palette.primary.main, marginLeft: "-1.5rem"}}>{orderObject.orderTime}</Typography>

      <Typography sx={{gridColumn: "span 1", fontSize: "0.7rem"}}>Order reference:</Typography>
      <Typography sx={{gridColumn: "span 1", fontSize: "0.7rem", color: theme.palette.primary.main, marginLeft: "-1.5rem"}}>{orderObject.orderReference}</Typography>

      <Box 
        gridTemplateColumns="repeat(5, minmax(0, 1fr))"
        sx={{
          gridColumn: "span 2",
          borderRadius: "1rem",
          padding: "0.5rem",
          display: "grid",
        }}
      >
        <Box sx={{gridColumn: "span 2", textAlign: "center", margin:"auto"}}>
          <img src={`${apiBaseUrl}/assets/${orderObject.productImgReference}.png`} alt={orderObject.productImgReference} width="40%" style={{}}/>
        </Box>

        <Box sx={{gridColumn: "span 3", margin: "auto"}}>
          <Typography sx={{fontWeight: "bold", fontSize: "1.2rem"}}>{orderObject.productName}</Typography>
              <Box sx={{display: "block"}}>
                <Typography sx={{float: "left"}}>{orderObject.productPrice}</Typography>
                <Typography sx={{float: "right", fontSize: "0.7rem"}}>x{parseFloat(orderObject.productQuantity).toFixed(3)}</Typography>
              </Box>
        </Box>
      </Box>
{/* 
      <Typography sx={{gridColumn: "span 1", fontSize: "0.7rem"}}>Total order amount:</Typography>
      <Typography sx={{gridColumn: "span 1", fontSize: "0.7rem", color: theme.palette.primary.main}}>{parseFloat(orderObject.totalOrderAmount).toFixed(3)}</Typography>

      <Typography sx={{gridColumn: "span 1", fontSize: "0.7rem"}}>Commission:</Typography>
      <Typography sx={{gridColumn: "span 1", fontSize: "0.7rem", color: theme.palette.primary.main}}>{parseFloat(orderObject.commission).toFixed(3)}</Typography>

      <Typography sx={{gridColumn: "span 1", fontSize: "0.7rem"}}>Estimated return:</Typography>
      <Typography sx={{gridColumn: "span 1", fontSize: "0.7rem", color: theme.palette.primary.main}}>{parseFloat(orderObject.estimatedReturn).toFixed(3)}</Typography>
     */}

          <Typography sx={{gridColumn: "span 1", fontSize: "0.7rem"}}>Total order amount:</Typography>
          <Typography sx={{gridColumn: "span 1", color: theme.palette.primary.main}}>{parseFloat(orderObject.totalOrderAmount).toFixed(3)}</Typography>
          
          <Typography sx={{gridColumn: "span 1"}}>Commission:</Typography>
          <Typography sx={{gridColumn: "span 1", color: theme.palette.primary.main}}>{parseFloat(orderObject.commission).toFixed(3)}</Typography>

          <Typography sx={{gridColumn: "span 1"}}>Estimated return:</Typography>
          <Typography sx={{gridColumn: "span 1", color: theme.palette.primary.main}}>{parseFloat(orderObject.estimatedReturn).toFixed(3)}</Typography> 

      {/* {orderObject.orderCompleted ?       
        <Typography sx={{margin: "auto", gridColumn: "span 2", fontSize: "0.8rem", color: theme.palette.primary.main}}>Completed!</Typography>
        : <Typography sx={{margin: "auto", gridColumn: "span 2", fontSize: "0.8rem", color: theme.palette.error.main}}>Not Completed!</Typography>
      } */}
    </Card>
    ))
  );
}

export default OrderComponent;