import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { default as TawkMessengerReact } from "@tawk.to/tawk-messenger-react";
import AccountPage from "pages/AccountPage/AccountPage";
import AdminReportPage from "pages/AdminReportPage/AdminReportPage";
import AffiliateReportPage from "pages/AffiliateReportPage/AffiliateReportPage";
import HistoryPage from "pages/HistoryPage/HistoryPage";
import HomePage from "pages/HomePage/HomePage";
import LoginPage from "pages/LoginPage/LoginPage";
import RegisterPage from "pages/LoginPage/RegisterPage";
import ResetPasswordPage from "pages/LoginPage/ResetPasswordPage";
import SpinPage from "pages/SpinPage/SpinPage";
import TeamReportPage from "pages/TeamReportPage/TeamReportPage";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeOptions } from "./resources/mui_theme";

let theme = createTheme(themeOptions);

function App() {
  const isAuth = Boolean(useSelector((state) => state.token));
  const isUser = useSelector((state) => state.user?.role === "user");
  const isAdmin = useSelector((state) => state.user?.role === "admin");
  const isAffiliate = useSelector((state) => state.user?.role === "affiliate");

  const tawkMessengerRef = useRef(null);

  return (
    <>
      <TawkMessengerReact
        propertyId={process.env.REACT_APP_TAWK_PROPERTY_ID}
        widgetId={process.env.REACT_APP_TAWK_WIDGET_ID}
        ref={tawkMessengerRef}
      />
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route
              path="/reset-password"
              element={
                <ResetPasswordPage tawkMessengerRef={tawkMessengerRef} />
              }
            />
            <Route
              path="/home"
              element={isAuth ? <HomePage /> : <Navigate to="/" />}
            />
            <Route
              path="/history"
              element={isAuth ? <HistoryPage /> : <Navigate to="/" />}
            />
            <Route
              path="/spin"
              element={isAuth ? <SpinPage /> : <Navigate to="/" />}
            />
            <Route
              path="/team-report"
              element={
                isAuth ? (
                  isUser ? (
                    <TeamReportPage />
                  ) : isAdmin ? (
                    <AdminReportPage />
                  ) : isAffiliate ? (
                    <AffiliateReportPage />
                  ) : (
                    <Navigate to="/" />
                  )
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/account"
              element={
                isAuth ? (
                  <AccountPage tawkMessengerRef={tawkMessengerRef} />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
