import { Paper, Typography } from "@mui/material";

const PaperButton = ({icon, label, event, isNonMobileScreen}) => {
  return(
    <>
      <Paper 
        elevation={10} 
        sx={{
          gridColumn: "span 1",
          borderRadius: "1rem",
          padding: "0.5rem",
          display: 'flex',
          justifyContent: 'center',   
          alignItems: 'center',       
          flexDirection: 'column',
          width: isNonMobileScreen ? "14vw" : "28vw",
          height: isNonMobileScreen ? "14vw" : "28vw",
          marginLeft: "auto",
          marginRight: "auto",
          '&:hover': {
            cursor: "pointer",
            boxShadow: 3
          },
        }}
        onClick={event}
      >
        {icon}
        <Typography sx={{fontSize: "0.8rem"}}>{label}</Typography>
      </Paper>
    </>    
  );
}

export default PaperButton;