import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  navigationPageNo: 0,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setLogout: (state) => {
      state = null;
    },
    setNavigationPageNo: (state, action) => {
      state.navigationPageNo = action.payload.navigationPageNo;
    },
  },
});

export const { setLogin, setLogout, setNavigationPageNo } = authSlice.actions;
export default authSlice.reducer;
