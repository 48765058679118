import { ArrowBack } from "@mui/icons-material";
import { Box, Chip, Divider, IconButton, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

const InviteWindow = ({onBackClick}) => {

  const theme = useTheme();
  const user = useSelector((state) => state.user);
  // const [open, setOpen] = useState(false);

  const baseUrl = window.location.origin;

  // const copy = (text) => {
  //   navigator.clipboard.writeText(text);
  //   setOpen(true)
  // }

  return(
    <>
      {/* <Snackbar
        message="Copied to clibboard!"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      /> */}

      <Box backgroundColor={theme.palette.background.paper} width="100%" height="100%"
      sx = {{
        overflow: "hidden",
        overflowY: "auto",
        paddingBottom: "8em"
      }}
      >
        <IconButton
          edge="start"
          color="primary"
          aria-label="back"
          onClick={onBackClick}
        >
          <ArrowBack sx={{fontSize: "30px", margin: "10px"}}/>
        </IconButton>

        <Typography sx={{fontSize: "3rem", textAlign: "center", marginBottom: "1rem"}}>Invite people to join your team!</Typography>
        <Divider sx={{margin: "2.6em 0.5rem 0.5rem"}}>
          <Chip label="Invite Code" />
        </Divider>
        {/* <Typography sx={{fontSize: "2rem", textAlign: "center"}}>Your invite code:</Typography> */}
        <Typography sx={{color: theme.palette.primary.main, fontSize: "2.3rem", textAlign: "center"}}>{user.inviteCode}
          {/* <ContentCopy onClick={()=>{copy(user.inviteCode)}} sx={{marginLeft: "10px", color: '#ae6000', cursor: "pointer"}}/> */}
        </Typography>
        <Divider sx={{margin: "2.6em 0.5rem 0.5rem"}}>
          <Chip label="Invite Link" />
        </Divider>
        {/* <Typography sx={{fontSize: "2rem", textAlign: "center"}}>Your invite link:</Typography> */}
        <Typography sx={{color: theme.palette.primary.main, fontSize: "1.5rem", textAlign: "center"}}>{baseUrl}/register?inviteCode={user.inviteCode}
          {/* <ContentCopy onClick={()=>{copy(`${baseUrl}/register?inviteCode=${user.inviteCode}`)}} sx={{marginLeft: "10px", color: '#ae6000', cursor: "pointer"}}/> */}
        </Typography>

      </Box>
    </>
  );
}

export default InviteWindow;