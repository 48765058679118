import { Box, Pagination, Skeleton, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import GenericOrderComponent from "components/GenericOrderComponent";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppHeader from "widgets/AppHeader/AppHeader";
import Navigation from "widgets/Navigation/Navigation";

const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const HistoryPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 600px)");


  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);

  const axiosHeaders = {headers: {Authorization: "Bearer " + token}}
  const [loading, setLoading] = useState(true);

  const [pageState, setPageState] = useState({
    pageSize: isNonMobileScreens ? 8 : 6,
    noOfPages: 1,
    selectedPage: 1,
    activeTab: 0,
    ordersTabData: null,
    teamCommissionsTabData: null,
    walletTransactionTabData: null,
  });
  
  const handleTabsChange = (event, newValue) => {
    setPageState(prevState => ({ ...prevState, activeTab: newValue, selectedPage: 1 }));
    // console.log(pageState)
    // fetchData(newValue);
  };

  const handlePageChange = (event, newPage) => {
    setPageState(prevState => ({ ...prevState, selectedPage: newPage }));
  };

  useEffect(() => {
    // Fetch data for each tab when the component mounts
    // console.log(pageState);
    fetchData(pageState.activeTab);
  }, []);

  useEffect(() => {
    // Fetch data for each tab when the component mounts
    // console.log(pageState);
    fetchData(pageState.activeTab);
  }, [pageState.activeTab, pageState.selectedPage]);

  const fetchData = (tabIndex) => {
    // Define the API endpoints for each tab
    const endpoints = [
      apiBaseUrl + '/orders/orders',
      apiBaseUrl + '/orders/team',
      apiBaseUrl + '/orders/wallet',
    ];

    const tabDataKeys = [
      'ordersTabData',
      'teamCommissionsTabData',
      'walletTransactionTabData'
    ];

    setLoading(true);
    // Make a GET request to the corresponding endpoint
    axios.get(endpoints[tabIndex], 
      { params: {
          page: pageState.selectedPage, 
          size: pageState.pageSize
        },
        ...axiosHeaders
      })
      .then((response) => {
        // Update the state with the fetched data
        const tabDataKey = tabDataKeys[tabIndex]
        setPageState(prevState => ({ ...prevState, [tabDataKey]: response.data.orders, 
          noOfPages: Math.ceil(response.data.totalCount / prevState.pageSize) === 0 ? 1 : Math.ceil(response.data.totalCount / prevState.pageSize)}));
        setLoading(false);
      })
      .catch((error) => {
        console.error(`Error fetching data for tab${tabIndex + 1}: ${error.message}`);
      });
  }

  return(
    <>
      <AppHeader/>
      <Box backgroundColor={theme.palette.background.paper} width="100%" height="100%"
      sx = {{
        overflow: "hidden",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "8em"
      }}
      >
        <Tabs value={pageState.activeTab} onChange={handleTabsChange} centered variant="fullWidth">
          <Tab label="Orders" />
          <Tab label="Team commissions" />
          <Tab label="Wallet transactions" />
        </Tabs>
        <Box
          display="grid"
          gap= {isNonMobileScreens ? "30px" : "10px"}
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            padding: "1rem"
          }}
        >
          {pageState.activeTab === 0 && pageState.ordersTabData && (
            loading ? (
              <Skeleton variant="rounded" height= "100%" sx={{gridColumn: "span 4"}}/>
            ) :
             (
              pageState.ordersTabData.map(order => (
                <GenericOrderComponent orderObject={order} isSpinOrder={true} />
              ))
             )
            )
          }
          {pageState.activeTab === 1 && pageState.teamCommissionsTabData && (
            loading ? (
              <Skeleton variant="rounded" height= "100%" sx={{gridColumn: "span 4"}}/>
            ) :
              pageState.teamCommissionsTabData.map(order => (
                <GenericOrderComponent orderObject={order} isTeamCommission={true} />
              ))
            )
          }
          {pageState.activeTab === 2 && pageState.walletTransactionTabData && (
            loading ? (
              <Skeleton variant="rounded" height= "100%" sx={{gridColumn: "span 4"}}/>
            ) :
              pageState.walletTransactionTabData.map(order => (
                <GenericOrderComponent orderObject={order} isWalletTransaction={true} />
              ))
            )
          }
        </Box>
        <Pagination count={pageState.noOfPages} color="secondary" 
          page={pageState.selectedPage}
          onChange={handlePageChange}
          sx = {{ 
            display: "flex", 
            justifyContent: "center",
            marginBottom: "15vh"
          }}/>
      </Box>
      <Navigation/>
    </>
  )
}

export default HistoryPage;