import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";

const AboutUsWindow = ({onBackClick}) => {

  const theme = useTheme();

  return(
    <>
      <Box backgroundColor={theme.palette.background.paper} width="100%" height="100%"
      sx = {{
        overflow: "hidden",
        overflowY: "auto",
        paddingLeft: "1em",
        paddingRight: "1em",
        paddingBottom: "8em"
      }}
      >
        <IconButton
          edge="start"
          color="primary"
          aria-label="back"
          onClick={onBackClick}
        >
          <ArrowBack sx={{fontSize: "30px", margin: "10px"}}/>
        </IconButton>

        <Typography sx={{color: theme.palette.primary.main, fontSize: "1.5rem", textAlign: "center", marginBottom: "1rem"}}>About Us</Typography>
        <Typography sx={{fontSize: "1rem", marginBottom: "1rem"}}>        
          Welcome to Rebeem, where we believe in the power of collective success and the spirit of entrepreneurship. We are a dynamic team dedicated to revolutionizing the dropshipping landscape by empowering individuals to earn while they shop, taking advantage of Artificial Intelligence.
        </Typography>

        <Typography sx={{fontSize: "1rem", marginBottom: "1rem"}}>        
          At Rebeem, we've reimagined traditional dropshipping by introducing a groundbreaking model that benefits everyone involved. Our platform gathers funds for large-scale dropshipping projects, allowing users to participate and earn commissions on every sale generated.
        </Typography>


        <Typography sx={{fontSize: "1rem", marginBottom: "1rem"}}>        
        Our mission is to democratize e-commerce, providing opportunities for individuals from all walks of life to generate income and achieve financial independence. Through our innovative approach, we aim to create a thriving community of entrepreneurs who share in the rewards of our collective success.
        </Typography>


        <Typography sx={{fontSize: "1rem", marginBottom: "1rem"}}>        
        What sets us apart is our commitment to fairness and transparency. We provide our users with real-time insights into their earnings and offer unparalleled support to help them maximize their potential in the world of dropshipping.
        </Typography>

        <Typography sx={{fontSize: "1rem", marginBottom: "1rem"}}>        
        This approach combines entrepreneurial spirit with a deep sense of social responsibility. By harnessing the potential of dropshipping, we've built a platform that enables individuals and businesses to contribute to meaningful causes effortlessly. With each purchase made through our platform, a portion of the proceeds goes directly towards our charitable endeavors.
        </Typography>

        <Typography sx={{fontSize: "1rem", marginBottom: "1rem"}}>        
        Join us on our journey to redefine e-commerce and unlock new opportunities for prosperity. Together, we can build a brighter future where everyone has the chance to thrive.
        </Typography>

        <Typography sx={{fontSize: "1rem", marginBottom: "1rem"}}>        
        Thank you for choosing Rebeem.
        </Typography>

        <Typography sx={{fontSize: "1rem"}}>        
        Warm regards,
        </Typography>
        <Typography sx={{fontSize: "1rem", marginBottom: "1rem"}}>        
        Rebeem Team
        </Typography>

{/*         
        <Divider sx={{margin: "1.2em 0.5em 1.2rem 0.5rem"}}>
          <Chip label="Withdraw request form" />
        </Divider> */}
      </Box>
    </>
  );
}

export default AboutUsWindow;