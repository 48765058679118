export const themeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#f7931a",
    },
    secondary: {
      main: "#ae6000",
    },
    background: {
      default: "#0b0c10",
      paper: "#1f2833",
    },
    divider: "#f7931a",
    text: {
      primary: "#fbfbff",
    },
  },
  typography: {
    fontFamily: "Rubik",
  },
  props: {
    MuiAppBar: {
      color: "secondary",
    },
  },
  shape: {
    borderRadius: 4,
  },
  overrides: {
    // MuiButton: {
    //   root: {
    //     background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    //     // background: "red",
    //     border: 0,
    //     borderRadius: 3,
    //     boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    //     color: "white",
    //     height: 48,
    //     padding: "0 30px",
    //   },
    // },
  },
};
