export const ViewEnum = {
  HOME: 0,
  INVITE: 1,
  DEPOSIT: 2,
  WITHDRAW: 3,
  ABOUT_US: 4,
  TUTORIAL: 5,
  EVENTS: 6,
  FINANCES: 7,
  ACCOUNT: 8,
  PROFILE: 9,
  TEAM_REPORT: 10,
  USER_BALANCE_ADMIN: 11,
  DEPOSIT_ADMIN: 12,
  WITHDRAW_ADMIN: 13,
  SPIN: 14,
};
