import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Snackbar, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import axios from "axios";
import { Formik } from "formik";
import { forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { resetPasswordValidationSchema } from "./validation";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const ResetPasswordPage = ({tawkMessengerRef}) => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 600px)");
  const navigate = useNavigate();

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    position: {
      vertical: 'top',
      horizontal: 'right',
    },
    snackMessage: '',
    severity: '',
  });

  const [loading, setLoading] = useState(false);


  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleSnackbarOpen = (newSnackMessage, severity) => {
    setSnackbarState({ ...snackbarState, open: true, snackMessage: newSnackMessage, severity: severity });
  };

  const initialValuesResetPassword = {
    email: '',
    pin: '',
    password: '',
    confirm_password: '',
  };

  const resetPassword = async(values, onSubmitProps) => {
    setLoading(true);
    axios.post(apiBaseUrl + "/auth/reset-password", values )
      .then(res => {
        onSubmitProps.resetForm();
        handleSnackbarOpen("Password changed succesfully!", 'success');
        setLoading(false);
        navigate("/");
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {
            onSubmitProps.setFieldError(error.response.data.field, error.response.data.error)
            handleSnackbarOpen(error.response.data.error, 'error');
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          handleSnackbarOpen("Error request: " + error.request, 'error');

        } else {
          // Something happened in setting up the request that triggered an Error
          handleSnackbarOpen('Error ' + error.message, 'error');
        }
      });
  }

  const handleFormSubmit = async (values, onSubmitProps) => {
    await resetPassword(values, onSubmitProps);
  };

  return(
    <Box backgroundColor={theme.palette.background.paper} width="100%" height="100%"
      sx = {{
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      <Snackbar
        anchorOrigin={snackbarState.position}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        autoHideDuration={6000}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarState.severity} sx={{ width: '100%' }}>
          {snackbarState.snackMessage}
        </Alert>
      </Snackbar>

      <Box
        width="100%"
        p="1rem 6%"
        textAlign="center"
      >
        <img src={`${apiBaseUrl}/assets/rebeemlogo.png`} alt="logo" width={isNonMobileScreens ? "20%" : "50%"}/>
      </Box>
      <Box
        width={isNonMobileScreens ? "50%" : "93%"}
        p="1rem 2rem"
        m="0rem auto"
        borderRadius="1.5rem"
        backgroundColor={theme.palette.background.paper}
        textAlign="center"
      >
        <Formik
          onSubmit={ handleFormSubmit }
          initialValues={ initialValuesResetPassword }
          validationSchema={ resetPasswordValidationSchema }
          enableReinitialize={ true }
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap= {isNonMobileScreens ? "30px" : "10px"}
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobileScreens ? undefined : "span 4" },
                }}
              >
                <TextField
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={Boolean(touched.email) && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  size={isNonMobileScreens ? "normal" : "small" }
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  label="PIN"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.pin}
                  name="pin"
                  error={Boolean(touched.pin) && Boolean(errors.pin)}
                  helperText={(touched.pin && errors.pin ? errors.pin : "") + " This is the PIN you choose when registering."}
                  size={isNonMobileScreens ? "normal" : "small" }
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  label="Password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={Boolean(touched.password) && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  size={isNonMobileScreens ? "normal" : "small" }
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  label="Confirm Password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirm_password}
                  name="confirm_password"
                  error={Boolean(touched.confirm_password) && Boolean(errors.confirm_password)}
                  helperText={touched.confirm_password && errors.confirm_password}
                  size={isNonMobileScreens ? "normal" : "small" }
                  sx={{ gridColumn: "span 4" }}
                />
              </Box>

              <Box>
                <LoadingButton
                  fullWidth
                  type="submit"
                  loading={loading}
                  sx={{
                    m: "2rem 0",
                    p: "1rem",
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.background.paper,
                    "&:hover": { 
                      backgroundColor: theme.palette.secondary.main,
                    },
                  }}
                >
                  { "RESET PASSWORD" }
                </LoadingButton>
                <Typography
                  onClick={() => {
                    tawkMessengerRef.current.maximize()
                }}
                  sx={{
                    color: theme.palette.primary.main,
                    "&:hover": {
                      cursor: "pointer",
                      color: theme.palette.secondary.light,
                    },
                  }}
                >
                  { "Forgot your PIN as well? Contact Us." }
                </Typography>
                <Typography
                  onClick={() => {
                    navigate("/")
                  }}
                  sx={{
                    color: theme.palette.primary.main,
                    "&:hover": {
                      cursor: "pointer",
                      color: theme.palette.secondary.light,
                    },
                  }}
                >
                  { "Go back to Login!" }
                </Typography>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default ResetPasswordPage;