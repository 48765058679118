import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import './slot.style.css';

const SlotReelv2 = ({spin, images, target}) => {

  const theme = useTheme()
  const isNonMobileScreens = useMediaQuery("(min-width: 600px)");
  const imgPositionInWheel = idx => -idx * (360 / images.length);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (spin) setFlag(true);
  }, [spin]);

  return(
      <motion.div className="Slot" style={{ height: isNonMobileScreens ? "24rem" : "14rem",}}>
        {!spin ? 
        <Typography
          sx={{
            fontSize: isNonMobileScreens ? "3rem" : "1rem",
            color: theme.palette.primary.main,
          }}
        >
          { "Press Spin!" }
        </Typography>
        :images.map((url, idx) => (
          // eslint-disable-next-line jsx-a11y/alt-text
          <motion.img
            key={idx}
            src={url}
            style={{
              originZ: isNonMobileScreens ? "-11rem" : "-6rem",
              rotateX: imgPositionInWheel(idx),
              bottom: isNonMobileScreens ? "7rem" : "5.5rem",
            }}
            animate={{
              rotateX: -360 * (target) + imgPositionInWheel(idx + target),
            }}
            transition={{ type: "tween", duration: 4 }}
          />
        ))}
      </motion.div>
    );
}
  
export default SlotReelv2;