import { Box, Card, Paper, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import WidgetWrapper from "components/WidgetWrapper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppHeader from "widgets/AppHeader/AppHeader";
import Navigation from "widgets/Navigation/Navigation";

const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const columns = [
  {
    field: "username",
    headerName: "Username",
    width: 200
  },
  {
    field: "level",
    headerName: "Lvl",
    disableColumnMenu: true,
    width: 70
  },
  {
    field: "referralLayer",
    headerName: "Referral Layer",
    disableColumnMenu: true
  },
  {
    field: "balance",
    headerName: "Balance",
    disableColumnMenu: true
  },
  {
    field: "depositAmount",
    headerName: "Deposit",
    disableColumnMenu: true
  },
  {
    field: "withdrawAmount",
    headerName: "Withdraw",
    disableColumnMenu: true
  }
];

const AdminReportPage = () => {

  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 600px)");

  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  
  const axiosHeaders = {headers: {Authorization: "Bearer " + token}}

  const [teamReport, setTeamReport] = useState({members: []});
  const [teamReportLoading, setTeamReportLoading] = useState(false);

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setTeamReportLoading(true)
    axios.get(apiBaseUrl + "/users/" + user._id + "/team-report/affiliate", axiosHeaders)
    .then(res => {
      setTeamReport(res.data)
      setTeamReportLoading(false)
    })
    .catch(function(err) {
      if (err.response) {
        if (err.response.status === 400) {
          console.log(err)
        }
      } else if (err.request) {
        console.log(err)
      } else {
        console.log(err)
      }
    });

    setRefresh(false);
  }, [refresh])

  return(
    <>
      <AppHeader/>
      <Box backgroundColor={theme.palette.background.paper} width="100%" height="100%"
      sx = {{
        overflow: "hidden",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "8em"
      }}
      >
        {teamReportLoading ? (
            <Skeleton variant="rounded" height= "100%"/>
          ) : (
          <>
          <WidgetWrapper sx={{width: isNonMobileScreens ? "85%" : "95%", marginLeft: "auto", marginRight: "auto", marginBottom: "1vh"}}>
            <Card
                sx={{
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                  display: "grid"
                }}
              >
                <Typography sx={{fontWeight: "bold", fontSize: "1.2rem", gridColumn: "span 2", justifyContent: "center", display: "flex"}}>Team Report</Typography>
                <Paper elevation={3} sx={{
                  gridColumn: "span 1",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  display: 'flex',
                  justifyContent: 'center',   
                  alignItems: 'center',       
                  flexDirection: 'column',
                  margin: "0.5rem"
                }}>
                  <Typography sx={{fontSize: "0.8rem"}}>Team Balance:</Typography>
                  <Typography sx={{fontSize: "1.5rem", color: theme.palette.primary.main}}>{parseFloat(teamReport.teamBalance).toFixed(3)}</Typography>
                </Paper>
                <Paper elevation={3} sx={{
                  gridColumn: "span 1",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  display: 'flex',
                  justifyContent: 'center',   
                  alignItems: 'center',       
                  flexDirection: 'column',
                  margin: "0.5rem"
                }}>
                  <Typography sx={{fontSize: "0.8rem"}}>Team size:</Typography>
                  <Typography sx={{fontSize: "1.5rem", color: theme.palette.primary.main}}>{teamReport.teamSize}</Typography>
                </Paper>
                <Paper elevation={3} sx={{
                  gridColumn: "span 1",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  display: 'flex',
                  justifyContent: 'center',   
                  alignItems: 'center',       
                  flexDirection: 'column',
                  margin: "0.5rem"
                }}>
                  <Typography sx={{fontSize: "0.8rem"}}>Team Deposit:</Typography>
                  <Typography sx={{fontSize: "1.5rem", color: theme.palette.primary.main}}>{parseFloat(teamReport.teamDeposit).toFixed(3)}</Typography>
                </Paper>
                <Paper elevation={3} sx={{
                  gridColumn: "span 1",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  display: 'flex',
                  justifyContent: 'center',   
                  alignItems: 'center',       
                  flexDirection: 'column',
                  margin: "0.5rem"
                }}>
                  <Typography sx={{fontSize: "0.8rem"}}>Team withdraw:</Typography>
                  <Typography sx={{fontSize: "1.5rem", color: theme.palette.primary.main}}>{parseFloat(teamReport.teamWithdraw).toFixed(3)}</Typography>
                </Paper>
                <Paper elevation={3} sx={{
                  gridColumn: "span 1",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  display: 'flex',
                  justifyContent: 'center',   
                  alignItems: 'center',       
                  flexDirection: 'column',
                  margin: "0.5rem"
                }}>
                  <Typography sx={{fontSize: "0.8rem"}}>New members today:</Typography>
                  <Typography sx={{fontSize: "1.5rem", color: theme.palette.primary.main}}>{teamReport.newMembersToday}</Typography>
                </Paper>
                <Paper elevation={3} sx={{
                  gridColumn: "span 1",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                  display: 'flex',
                  justifyContent: 'center',   
                  alignItems: 'center',       
                  flexDirection: 'column',
                  margin: "0.5rem"
                }}>
                  <Typography sx={{fontSize: "0.8rem"}}>Active members today:</Typography>
                  <Typography sx={{fontSize: "1.5rem", color: theme.palette.primary.main}}>{teamReport.activeMembersToday}</Typography>
                </Paper>
            </Card>
          </WidgetWrapper>
          
          <WidgetWrapper sx = {{marginBottom: "8rem"}}>
          <DataGrid
            rows={teamReport.members}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            // pageSizeOptions={[5]}
            // checkboxSelection
            disableRowSelectionOnClick
          />
          </WidgetWrapper>
        </>)}
      </Box>
      <Navigation/>
    </>
  );
}

export default AdminReportPage;