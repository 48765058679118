import { LoadingButton } from '@mui/lab';
import {
  Box,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import axios from "axios";
import { Formik } from "formik";
import { forwardRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogin } from "state";
import { loginValidationSchema } from "./validation";

// const Transition = forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const LoginPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 600px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [openForgetPasswordDialog, setOpenForgetPasswordDialog] = useState(false);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    position: {
      vertical: 'top',
      horizontal: 'right',
    },
    snackMessage: '',
    severity: '',
  });

  const [loading, setLoading] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleSnackbarOpen = (newSnackMessage, severity) => {
    setSnackbarState({ ...snackbarState, open: true, snackMessage: newSnackMessage, severity: severity });
  };

  const initialValuesLogin = {
    email: "",
    password: "",
  };

  // const handleClose = () => {
  //   setOpenForgetPasswordDialog(false);
  // };

  const login = async(values, onSubmitProps) => {
    setLoading(true);
    axios.post(apiBaseUrl + "/auth/login", values )
    .then(res => {
      onSubmitProps.resetForm();
      setLoading(false);
      dispatch(
        setLogin({
          user: res.data.user,
          token: res.data.token,
        })
      );
      navigate("/home");
    })
    .catch(function (error) {
      setLoading(false);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 400) {
          onSubmitProps.setFieldError(error.response.data.field, error.response.data.error)
          handleSnackbarOpen(error.response.data.error, 'error');
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error);
        handleSnackbarOpen("Error request: " + error.request.responseText, 'error');

      } else {
        // Something happened in setting up the request that triggered an Error
        handleSnackbarOpen('Error ' + error.message, 'error');
      }
    });
  }

  const handleFormSubmit = async (values, onSubmitProps) => {
    await login(values, onSubmitProps);
  };

  return(
    <Box backgroundColor={theme.palette.background.paper} width="100%" height="100%"
      sx = {{
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      <Snackbar
        anchorOrigin={snackbarState.position}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        autoHideDuration={6000}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarState.severity} sx={{ width: '100%' }}>
          {snackbarState.snackMessage}
        </Alert>
      </Snackbar>
      {/* <Dialog
        open={openForgetPasswordDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Forgot your password?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            If you have forgotten your password, text us on <b>Telegram</b> or <b>WhatsApp</b> and we will assist you immediately.
          </DialogContentText>
          <FlexBetween 
            sx = {{ paddingTop: "2rem" }}
          >
            <TelegramIcon 
              sx={{ 
                fontSize:"6rem", 
                borderRadius: "1rem", 
                border: "1px solid",
                padding: "0.5rem",
                "&:hover": {
                  cursor: "pointer",
                  color: theme.palette.primary.light,
                },
              }}
            />
            <WhatsAppIcon
              sx={{ 
                fontSize:"6rem", 
                borderRadius: "1rem", 
                border: "1px solid",
                padding: "0.5rem",
                "&:hover": {
                  cursor: "pointer",
                  color: theme.palette.primary.light,
                },
              }}
            />
          </FlexBetween>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog> */}
      <Box
        width="100%"
        p="1rem 6%"
        m="5rem 0rem 0rem 0rem"
        textAlign="center"
      >
        <img src={`${apiBaseUrl}/assets/rebeemlogo.png`} alt="logo" width={isNonMobileScreens ? "50%" : "80%"}/>
      </Box>

      <Box
        width={isNonMobileScreens ? "50%" : "93%"}
        p="1rem 2rem"
        m="0rem auto"
        borderRadius="1.5rem"
        backgroundColor={theme.palette.background.paper}
        textAlign="center"
      >
        <Formik
          onSubmit={ handleFormSubmit }
          initialValues={ initialValuesLogin }
          validationSchema={ loginValidationSchema }
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobileScreens ? undefined : "span 4" },
                }}
              >
                <TextField
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={Boolean(touched.email) && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  size={isNonMobileScreens ? "normal" : "small" }
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  label="Password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={Boolean(touched.password) && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  size={isNonMobileScreens ? "normal" : "small" }
                  sx={{ gridColumn: "span 4" }}
                />
              </Box>

              <Box>
                <LoadingButton
                  fullWidth
                  type="submit"
                  loading={loading}
                  sx={{
                    m: "2rem 0",
                    p: "1rem",
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.background.paper,
                    "&:hover": { 
                      backgroundColor: theme.palette.secondary.main,
                    },
                  }}
                >
                  { "LOGIN" }
                </LoadingButton>
                <Typography
                  onClick={() => {
                    navigate("/reset-password")
                  }}
                  sx={{
                    color: theme.palette.primary.main,
                    paddingBottom: "1rem",
                    "&:hover": {
                      cursor: "pointer",
                      color: theme.palette.secondary.light,
                    },
                  }}
                >
                  { "Forgot your password?" }
                </Typography>
                <Typography
                  onClick={() => {
                    navigate("/register")
                  }}
                  sx={{
                    color: theme.palette.primary.main,

                    "&:hover": {
                      cursor: "pointer",
                      color: theme.palette.secondary.light,
                    },
                  }}
                >
                  { "No account? Press to Register!" }
                </Typography>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default LoginPage;