import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";

const TutorialWindow = ({onBackClick}) => {

  const theme = useTheme();

  return(
    <>
      <Box backgroundColor={theme.palette.background.paper} width="100%" height="100%"
      sx = {{
        overflow: "hidden",
        overflowY: "auto",
        paddingLeft: "1em",
        paddingRight: "1em",
        paddingBottom: "8em"
      }}
      >
        <IconButton
          edge="start"
          color="primary"
          aria-label="back"
          onClick={onBackClick}
        >
          <ArrowBack sx={{fontSize: "30px", margin: "10px"}}/>
        </IconButton>

        <Typography sx={{color: theme.palette.primary.main, fontSize: "1.5rem", textAlign: "center", marginBottom: "1rem"}}>Tutorial</Typography>

{/*         
        <Divider sx={{margin: "1.2em 0.5em 1.2rem 0.5rem"}}>
          <Chip label="Withdraw request form" />
        </Divider> */}
      </Box>
    </>
  );
}

export default TutorialWindow;