import { AccountBox, Info, LibraryBooks, PersonAdd, SupportAgent, Wallet } from "@mui/icons-material";
import { Card, useMediaQuery } from "@mui/material";
import PaperButton from "components/PaperButton";
import { ViewEnum } from "utils/views.constants";

const AccountPageMenu = ({setView, tawkMessengerRef}) => {
  const isNonMobileScreen = useMediaQuery("(min-width: 600px)");

  const handleClick = (view) => {
    setView(view);
  }

  return(
    <>
      <Card
        sx={{
          borderRadius: "1rem",
          padding: "0.5rem",
          gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
          display: "grid",
          gap: "0.75rem",
        }}
      >
        <PaperButton 
          icon={<AccountBox sx={{fontSize: isNonMobileScreen ? "80px" : "60px"}}/>}
          label="Personal Info"
          isNonMobileScreen={isNonMobileScreen}
          event={() => handleClick(ViewEnum.PROFILE)}
        />

        <PaperButton 
          icon={<Wallet sx={{fontSize: isNonMobileScreen ? "80px" : "60px"}}/>}
          label="Wallet"
          isNonMobileScreen={isNonMobileScreen}
          event={() => handleClick(ViewEnum.FINANCES)}
        />

        <PaperButton 
          icon={<Info sx={{fontSize: isNonMobileScreen ? "80px" : "60px"}}/>}
          label="About Us"
          isNonMobileScreen={isNonMobileScreen}
          event={() => handleClick(ViewEnum.ABOUT_US)}
        />

        <PaperButton 
          icon={<SupportAgent sx={{fontSize: isNonMobileScreen ? "80px" : "60px"}}/>}
          label="Support"
          isNonMobileScreen={isNonMobileScreen}
          event={() => {tawkMessengerRef.current.toggleVisibility();}}
        />

        <PaperButton 
          icon={<PersonAdd sx={{fontSize: isNonMobileScreen ? "80px" : "60px"}}/>}
          label="Invite friend"
          isNonMobileScreen={isNonMobileScreen}
          event={() => handleClick(ViewEnum.INVITE)}
        />

        <PaperButton 
          icon={<LibraryBooks sx={{fontSize: isNonMobileScreen ? "80px" : "60px"}}/>}
          label="Start tutorial"
          isNonMobileScreen={isNonMobileScreen}
          event={() => handleClick(ViewEnum.TUTORIAL)}
        />
      </Card>
    </>
  );
};

export default AccountPageMenu;