import { ArrowBack, HighlightOff } from "@mui/icons-material";
import { LoadingButton } from '@mui/lab';
import { Box, Chip, Divider, IconButton, Snackbar, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import axios from "axios";
import { Formik } from "formik";
import { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const WithdrawAdminWindow = ({onBackClick, requestToHandle}) => {

  const theme = useTheme();
  const token = useSelector((state) => state.token);
  const isNonMobileScreen = useMediaQuery("(min-width: 600px)");
  const [refreshRequest, setRefreshRequst] = useState(false);
  const [request, setRequest] = useState({});
  
  const axiosHeaders = {headers: {Authorization: "Bearer " + token}}

  const [loading, setLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    position: {
      vertical: 'top',
      horizontal: 'right',
    },
    snackMessage: '',
    severity: '',
  });


 
  const initialValuesWithdraw = {
    withdrawAmount: 0,
  };

  const handleWithdrawSubmit = async (values, onSubmitProps) => {
    await withdraw(values, onSubmitProps);
    await completeRequest(true);
    setRefreshRequst(true);
  };

  const withdraw = async(values, onSubmitProps) => {
    setLoading(true);
    const body = {
      userId: request.userId,
      value: values.withdrawAmount
    }
    axios.post(apiBaseUrl + "/balance/remove", body, axiosHeaders)
    .then(res => {
      onSubmitProps.resetForm();
      handleSnackbarOpen("Balance updated succesfully!", 'success');
      setLoading(false);
    })
    .catch(function (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.status === 400) {
          onSubmitProps.setFieldError(error.response.data.field, error.response.data.error)
          handleSnackbarOpen(error.response.data.error, 'error');
        }
      } else if (error.request) {
        handleSnackbarOpen("Error request: " + error.request.responseText, 'error');

      } else {
        handleSnackbarOpen('Error ' + error.message, 'error');
      }
    });
  }

  const completeRequest = async() => {
    setLoading(true);
    const body = {
      userId: request.userId,
      requestId: request._id
    }
    axios.post(apiBaseUrl + "/requests/complete", body, axiosHeaders)
    .then(res => {
      handleSnackbarOpen("Request completed!", 'success');
      setLoading(false);
    })
    .catch(function (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.status === 400) {
          handleSnackbarOpen(error.response.data.error, 'error');
        }
      } else if (error.request) {
        handleSnackbarOpen("Error request: " + error.request.responseText, 'error');
      } else {
        handleSnackbarOpen('Error ' + error.message, 'error');
      }
    });
  }

  const failRequest = async() => {
    setLoading(true);
    const body = {
      userId: request.userId,
      requestId: request._id
    }
    axios.post(apiBaseUrl + "/requests/fail", body, axiosHeaders)
    .then(res => {
      handleSnackbarOpen("Request failed!", 'success');
      setLoading(false);
      setRefreshRequst(true)
    })
    .catch(function (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.status === 400) {
          handleSnackbarOpen(error.response.data.error, 'error');
        }
      } else if (error.request) {
        handleSnackbarOpen("Error request: " + error.request.responseText, 'error');
      } else {
        handleSnackbarOpen('Error ' + error.message, 'error');
      }
    });
  }
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleSnackbarOpen = (newSnackMessage, severity) => {
    setSnackbarState({ ...snackbarState, open: true, snackMessage: newSnackMessage, severity: severity });
  };

  useEffect(() => {
    setRefreshRequst(false)
    axios.get(apiBaseUrl + "/requests/" + requestToHandle.id, axiosHeaders)
    .then(res => {
      setRequest(res.data)
    })
    .catch(function(err) {
      if (err.response) {
        if (err.response.status === 400) {
          console.log(err)
        }
      } else if (err.request) {
        console.log(err)
      } else {
        console.log(err)
      }
    });
  }, [refreshRequest])

  return(
    <>
      <Box backgroundColor={theme.palette.background.paper} width="100%" height="100%"
      sx = {{
        overflow: "hidden",
        overflowY: "auto",
        paddingLeft: "1em",
        paddingRight: "1em",
        paddingBottom: "8em"
      }}
      >
         <Snackbar
          anchorOrigin={snackbarState.position}
          open={snackbarState.open}
          onClose={handleSnackbarClose}
          autoHideDuration={6000}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarState.severity} sx={{ width: '100%' }}>
            {snackbarState.snackMessage}
          </Alert>
        </Snackbar>
        <IconButton
          edge="start"
          color="primary"
          aria-label="back"
          onClick={onBackClick}
        >
          <ArrowBack sx={{fontSize: "30px", margin: "10px"}}/>
        </IconButton>

        <Typography sx={{color: theme.palette.primary.main, fontSize: "1.5rem", textAlign: "center", marginBottom: "1rem"}}>Withdraw Admin Window</Typography>
        
        <Divider sx={{margin: "1.2em 0.5em 1.2rem 0.5rem"}}>
          <Chip label="Reference" />
        </Divider>

        <Typography sx={{textAlign: "center"}}>{request._id}</Typography>

        <Divider sx={{margin: "1.2em 0.5em 1.2rem 0.5rem"}}>
          <Chip label="Type" />
        </Divider>

        <Typography sx={{textAlign: "center"}}>{request.type}</Typography>

        <Divider sx={{margin: "1.2em 0.5em 1.2rem 0.5rem"}}>
          <Chip label="Status" />
        </Divider>

        <Typography sx={{textAlign: "center"}}>{request.status}</Typography>

        {request.iban && (
          <>
          <Divider sx={{margin: "1.2em 0.5em 1.2rem 0.5rem"}}>
            <Chip label="Card IBAN" />
          </Divider>
  
          <Typography sx={{textAlign: "center"}}>{request.iban}</Typography>
          </>
        )}

        {request.nameOnCard && (
          <>
          <Divider sx={{margin: "1.2em 0.5em 1.2rem 0.5rem"}}>
            <Chip label="Name on Card" />
          </Divider>
  
          <Typography sx={{textAlign: "center"}}>{request.nameOnCard}</Typography>
          </>
        )}

        {request.userWalletId && (
          <>
          <Divider sx={{margin: "1.2em 0.5em 1.2rem 0.5rem"}}>
            <Chip label="User Wallet Id" />
          </Divider>
  
          <Typography sx={{textAlign: "center"}}>{request.userWalletId}</Typography>
          </>
        )}

        <Divider sx={{margin: "1.2em 0.5em 1.2rem 0.5rem"}}>
          <Chip label="Amount requested" />
        </Divider>

        <Typography sx={{textAlign: "center"}}>{request.amount}</Typography>

        <Divider sx={{margin: "1.2em 0.5em 1.2rem 0.5rem"}}>
          <Chip label="Users' username" />
        </Divider>

        <Typography sx={{textAlign: "center"}}>{request.userUsername}</Typography>

        <Divider sx={{margin: "1.2em 0.5em 1.2rem 0.5rem"}}>
          <Chip label="Handle" />
        </Divider>

        <Formik
        onSubmit={ handleWithdrawSubmit }
        initialValues={ initialValuesWithdraw }
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap= {isNonMobileScreen ? "30px" : "10px"}
                gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                sx={{
                  marginLeft: "1rem",
                  marginRight: "1rem",
                }}
              >
                <TextField
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.withdrawAmount}
                  name="withdrawAmount"
                  error={Boolean(touched.withdrawAmount) && Boolean(errors.withdrawAmount)}
                  helperText={touched.withdrawAmount && errors.withdrawAmount}
                  size={isNonMobileScreen ? "normal" : "small" }
                  sx={{ gridColumn: "span 2" }}
                />

                <LoadingButton
                  fullWidth
                  type="submit"
                  loading={loading}
                  disabled={request.status !== "PENDING"}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.background.paper,
                    gridColumn: "span 2",
                    "&:hover": { 
                      backgroundColor: theme.palette.secondary.main,
                    },
                  }}
                >
                  { "Complete Request" }
                </LoadingButton>
              </Box>
            </form>
          )}
        </Formik>
        <LoadingButton
          disabled={request.status !== "PENDING"}
          fullWidth
          loading={loading}
          onClick={() => failRequest()}
          sx={{
            backgroundColor: theme.palette.error.main,
            color: theme.palette.background.paper,
            // marginLeft: "1rem",
            // marginRight: "1rem",
            marginTop: "2rem",
            "&:hover": { 
              backgroundColor: theme.palette.secondary.main,
            },
          }}
        >
          <HighlightOff/>{ "Fail Request" }
        </LoadingButton>
      </Box>
    </>
  );
}

export default WithdrawAdminWindow;