import {
  AccountCircle,
  CurrencyExchange,
  Groups,
  History,
  Home
} from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setNavigationPageNo } from "state";
import "./navigation.style.css";

const Navigation = ({setToSpin}) => {

  const value = useSelector((state) => state.navigationPageNo);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <BottomNavigation
      style={{marginTop: "100px"}}
      className="main-navigation"
      showLabels
      value={value}
      onChange={(event, newValue) => {
        dispatch(
          setNavigationPageNo({
            navigationPageNo: newValue,
          })
        );
      }}
    >
      <BottomNavigationAction
        label="Home"
        icon={<Home />}
        onClick={() => {
          navigate("/home")
        }}
      />
      <BottomNavigationAction
        label="History"
        icon={<History />}
        onClick={() => navigate("/history")}
      />
      <BottomNavigationAction
        label="Spin"
        icon={<CurrencyExchange sx={{ color: "white" }} />}
        className="order-button"
        sx={{
          zIndex: 1,
          top: "-10px",
          left: 0,
          right: 0,
          background: "#ae6000",
          borderRadius: "50px",
          '& .Mui-selected': {
            color: "white",
          },
        }}
        onClick={() => {
          if(location.pathname === "/spin") 
            setToSpin(true) 
          else 
            navigate("/spin");
        }}
      />
      <BottomNavigationAction
        label="Team"
        icon={<Groups />}
        onClick={() => navigate("/team-report")}
      />
      <BottomNavigationAction
        label="Account"
        icon={<AccountCircle />}
        onClick={() => navigate("/account")}
      />
    </BottomNavigation>
  );
};

export default Navigation;
