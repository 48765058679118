import { VolumeUp } from "@mui/icons-material";
import './marquee.style.css';

const Marquee = ({text}) => {
  return (
    <div className="marquee">
      <marquee>{text}</marquee>
      <VolumeUp className="icon"/>
    </div>
  );
}

export default Marquee;