import * as Yup from "yup";

export const withdrawCryptoRequestValidationSchema = Yup.object().shape({
  walletId: Yup.string().required("Wallet ID required"),
  amount: Yup.number()
    .required("Amount required")
    .min(10, "Amount should be more than fees!"),
});

export const withdrawCardRequestValidationSchema = Yup.object().shape({
  iban: Yup.string().required("IBAN required"),
  nameOnCard: Yup.string().required("Name on Card required"),
});
