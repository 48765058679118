import { Avatar, Box, Card, Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import WidgetWrapper from "components/WidgetWrapper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const UserWidget = () => {

  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 600px)");

  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);

  // to replace with a get User instead of using the state from redux throught whole page
  const [requestedUser, setRequestedUser] = useState({});
  
  const axiosHeaders = {headers: {Authorization: "Bearer " + token}}

  useEffect(() => {
    axios.get(apiBaseUrl + "/users/" + user._id, axiosHeaders)
    .then(res => {
      setRequestedUser(res.data)
    })
    .catch(function (error) {
      console.error(error);
    });
  }, [])
  
  return(
    <>
      <WidgetWrapper sx={{width: isNonMobileScreens ? "85%" : "95%", marginLeft: "auto", marginRight: "auto"}}>
          <Card
            sx={{
              borderRadius: "1rem",
              padding: "0.5rem",
              gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
              display: "grid"
            }}
          >
            <Avatar
              alt={user.username}
              sx={{ bgcolor: theme.palette.primary.main, width: 80, height: 80, fontWeight: 600, gridColumn: "span 2", textAlign: "center", margin:"auto" }}
            >
              {user.username.charAt(0).toUpperCase()}
            </Avatar>

            <Box sx={{gridColumn: "span 3", margin: "auto"}}>
              <Typography sx={{fontWeight: "bold", fontSize: "1.4rem", color: theme.palette.primary.main, paddingBottom: "0.3rem"}}>{`${user.username}`}</Typography>
              <Typography component='div'><Box display="inline" sx={{color: theme.palette.primary.main}}>{`Level ${user.levelId} ( ${requestedUser?.directReferralsNo} / ${requestedUser?.requiredDirectReferralsForNextLevel} direct referrals )` }</Box></Typography>
              <Typography component='div'>Invite code: <Box display="inline" sx={{color: theme.palette.primary.main}}>{user.inviteCode}</Box></Typography>
            </Box>
            <Divider sx={{margin: "0.5rem", gridColumn: "span 5"}}/>
            <Typography component='div' sx={{gridColumn: "span 5", textAlign: "center", margin:"auto"}}>Available balance: <Box display="inline" sx={{color: theme.palette.primary.main}}>{`${parseFloat(user.balance).toFixed(2)} USDT`}</Box></Typography>
          </Card>
        </WidgetWrapper>
    </>
  );
}

export default UserWidget;