import { Group, Lock } from "@mui/icons-material";
import { Box, Card, Typography, useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const LevelCardsContainer = () => {
  const isNonMobileScreens = useMediaQuery("(min-width: 600px)");
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  const navigate = useNavigate();
  const [levels, setLevels] = useState([]);
  const [maxActiveUsers, setMaxActiveUsers] = useState(getRandomInt(8000,12000));

  const axiosHeaders = {headers: {Authorization: "Bearer " + token}}

  useEffect(() => {
    axios.get(apiBaseUrl + "/levels/", axiosHeaders)
    .then(res => {
      setLevels(res.data.filter(level => level.levelNumber !== 0))
    })
    .catch(function (error) {
      console.error(error);
    });

    const interval = setInterval(() => {
      setMaxActiveUsers(getRandomInt(8000,12000));
    }, 10000);

    return () => clearInterval(interval);
  }, [])

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }
  
  return(
    <Box
      display="grid"
      gap= {isNonMobileScreens ? "30px" : "10px"}
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      sx={{
        marginBottom: "15vh"
      }}
    >
      {levels.map(level => (
        <Card 
          onClick={() => {
          if (user.levelId >= level.levelNumber) {
            navigate("/spin");
          }}}
          sx={{
            gridColumn: isNonMobileScreens ? "span 2" : "span 4", 
            height:"20vh", 
            borderRadius: "1rem",
            padding: "0.5rem",
            display: "grid",
            gridTemplateColumns: 'repeat(5, minmax(0, 1fr))',
            position: "relative",
            "&:hover": {
              cursor: user.levelId >= level.levelNumber ? "pointer" : "default",
            },
          }}
          key={level.levelNumber}
        >    
            {user.levelId < level.levelNumber ?
              <Box sx={{
                height:"100%", 
                width:"100%",
                zIndex: "1",
                backgroundColor: "rgb(31, 40, 51, 0.6)",
                borderRadius: "1rem",
                position:"absolute",
              }}> 
                <Lock sx={{color: theme.palette.primary.main, fontSize: "5rem", margin:"auto", display: "block", height: "100%"}}/>
              </Box> 
              : undefined
            }
            <Box sx={{
                height:isNonMobileScreens ? "2rem" : "2rem", 
                width:"20%",
                right:"0.3rem",
                top:"0.3rem",
                zIndex: "1",
                // boxShadow: "inset 0 0 4px 1px " + theme.palette.primary.main,
                borderRadius: "1rem",
                position:"absolute",
                textAlign: "center",
              }}> 
                {/* <Typography sx={{fontSize: isNonMobileScreens ? "1rem" : "0.5rem"}}>Active users: </Typography> */}
                <Typography component='div' sx={{fontSize: isNonMobileScreens ? "1rem" : "0.6rem"}}><Box display="inline" sx={{color: theme.palette.primary.main}}><Group/> {Math.floor(maxActiveUsers/level.levelNumber)}</Box></Typography>
            </Box> 
            <Box sx={{gridColumn: "span 2", textAlign: "center", margin:"auto"}}>
              <img src={`${apiBaseUrl}/assets/stores/${level.referenceName}.png`} alt={level.referenceName} width="80%" style={{maxHeight: "18vh"}}/>
            </Box>
            <Box sx={{gridColumn: "span 3", margin: "auto"}}>
              <Typography sx={{fontWeight: "bold", fontSize: "1.1rem", color: theme.palette.primary.main, paddingBottom: "0.3rem"}}>{`Level ${level.levelNumber} - ${level.referenceName}`}</Typography>
              <Typography component='div' sx={{sfontSize: "0.9rem"}}>Entry Limit: <Box display="inline" sx={{color: theme.palette.primary.main}}>{level.lowerLimit} USDT</Box></Typography>
              <Typography component='div' sx={{fontSize: "0.9rem"}}>Daily Orders: <Box display="inline" sx={{color: theme.palette.primary.main}}>{level.dailyAllowedOrders}</Box></Typography>
              <Typography component='div' sx={{fontSize: "0.9rem"}}>Commission Rate: <Box display="inline" sx={{color: theme.palette.primary.main}}>{level.commissionRate}%</Box></Typography>
            </Box>
        </Card>
      ))}
    </Box>
  );
}
    
export default LevelCardsContainer;