import { Box, IconButton, Typography, useTheme } from "@mui/material";

const BubbleMenuButton = ({icon, label, event}) => {
  const theme = useTheme();

  return(
    <Box sx={{display: "grid", flexDirection: "column", position: "relative"}}>
    <IconButton aria-label={label} size="large"
      sx={{
        // background: "radial-gradient(rgb(31, 40, 51, 0.6) 20%, rgb(11, 12, 16) 60%)",
        width:"4rem", height:"4rem",
        boxShadow: "inset 0 0 4px 1px " + theme.palette.primary.main
      }}
      onClick={event}
    >
      {icon}
    </IconButton>
    <Typography align="center" justifyContent="center" width="4rem" sx={{
        fontSize: "0.7rem",
        position:"absolute",
        bottom: label.length > 10 ? "-2.5rem" : "-1.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        {label}
      </Typography>
    </Box>
  );
}

export default BubbleMenuButton;