import { Logout, SupportAgent } from '@mui/icons-material';
import { AppBar, Box, IconButton, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogout } from 'state';

const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const AppHeader = () => {
  const isNonMobileScreens = useMediaQuery("(min-width: 600px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getTimeZone = () => {
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
  }

  const logout = () => {
    dispatch(
      setLogout()
    );
    navigate("/");
  }

  return(
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar 
          sx={{ 
            display: "grid",
            gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
          }}
        >
          <Box sx={{ gridColumn: "span 1", justifyContent: "start"}}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="notifications"
            >
              <SupportAgent color='primary'/>
            </IconButton>
          </Box>

          <Box
            width="100%"
            sx={{ justifyContent: 'center', display: 'flex', gridColumn:"span 3"}}
          >
            <img src={`${apiBaseUrl}/assets/rebeemlogo.png`} alt="logo" width={isNonMobileScreens ? "120vh" : "80vh"}/>
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center', gridColumn:"span 1", justifyContent: "end"}}>
            <Typography variant="overline" component="div" sx={{marginRight: "1rem"}}>
              UTC{getTimeZone()}
            </Typography>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="logout"
              onClick={logout}
            >
              <Logout color='primary'/>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default AppHeader;