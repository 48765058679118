import { useMediaQuery } from "@mui/material";
import { Carousel } from "antd";
import './slideshow.style.css';

const apiBaseUrl = process.env.REACT_APP_API_ROOT_URI;

const Slideshow = () => {
  const isNonMobileScreens = useMediaQuery("(min-width: 600px)");
  
  return(
    <Carousel autoplay>
      <img 
        src={`${apiBaseUrl}/assets/slides/` + (isNonMobileScreens ? `desktop/` : `phone/`) + `event1.jpg`} 
        alt="slide1" 
        width = "100%" 
        height= {isNonMobileScreens ? "200px" : "140px"}
      />
      <img 
        src={`${apiBaseUrl}/assets/slides/` + (isNonMobileScreens ? `desktop/` : `phone/`) + `event2.jpg`} 
        alt="slide2" 
        width = "100%" 
        height= {isNonMobileScreens ? "200rem" : "140rem"}
      />
      <img 
        src={`${apiBaseUrl}/assets/slides/` + (isNonMobileScreens ? `desktop/` : `phone/`) + `event3.jpg`} 
        alt="slide3" 
        width = "100%" 
        height= {isNonMobileScreens ? "200rem" : "140rem"}
      />
    </Carousel>
  );
}
  
export default Slideshow;